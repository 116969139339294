p {
  line-height: 1.5 !important;
}
.reveal {
  position: relative;
  opacity: 0;
}
.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
.animation-bottom {
  animation: fade-bottom 1s ease-in;
}
.animation-left {
  animation: fade-left 1s ease-in;
}
.animation-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.hero_section {
  background-color: var(--header-bg);
  /* background-image: url(../assets/images/banner-background.webp);
  background-position: 50% 6%;
  background-repeat: no-repeat; */
}

.hero_main {
  /* padding-top: 200px; */
  height: calc(100vh - 83px);
  min-height: 600px;
}

.hero_image_main {
  margin-bottom: -3px;
  width: 80%;
}
.hero_text h1 {
  font-size: 50px;
  color: var(--white);
  margin-bottom: 30px;
  line-height: 1;
}
.hero_text p {
  font-size: 18px;
  color: #ffcd00;
  margin: 0px;
  font-family: "jokker-regular";
  line-height: 28px;
}
.hero_text {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  margin-bottom: 50px;
}
.sub_heading {
  color: var(--header-bg);
  font-size: 18px;
  margin: 0;
}

.main_heading {
  color: var(--header-bg);
  font-size: 50px;
  margin-top: 12px;
  margin-bottom: 30px;
}

.aboutText p {
  font-size: 20px;
  line-height: 28px;
  max-width: 515px;
  margin-bottom: 50px;
}
.about_image {
  display: flex;
  justify-content: end;
  padding-right: 15%;
}
.lenders_servicers_Text p {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 30px;
}
.lenders_servicers_image{
  display: flex;
  justify-content: end;
  align-items: center;
  overflow: hidden;
}
.lenders_servicers_content{
  padding-right: 0px !important;
}
.lenders_servicers_image img{
  margin-right: -30px;
}
.about_card {
  padding: 30px;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 24px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width: 100%; */
  min-height: 282px;
  /* max-width: 400px; */
}

.about_card h3 {
  margin: 0;
  font-size: 24px;
  font-family: "jokker-bold";
  color: var(--header-bg);
}

.groupHere {
  color: #09143a !important;
  font-weight: 600;
}

.mandatory {
  color: var(--danger-red);
  font-size: 12px;
  vertical-align: super;
}
.about_card p {
  margin: 0;
  font-size: 18px;
  color: var(--header-bg);
  /* max-width: 295px; */
  line-height: 24px;
}
.values_card {
  /* padding: 32px 0; */
  padding-top: 32px;
  padding-bottom: 0px;
  margin-top: 50px;
}
.values_card h2 {
  font-family: "jokker-bold";
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--header-bg);
  max-width: 250px;
}

.values_card h4 {
  margin-top: 0px;
  margin-bottom: unset;
  margin-top: 30px;
  margin-bottom: 30px;
}

.values_card p {
  font-family: "jokker-regular";
  font-size: 18px;
  margin: 0px;
  color: var(--header-bg);
  line-height: 24px;
  max-width: 320px;
}
.pr-75 {
  padding-right: 75px;
}
.border-l-r {
  border: 1px solid var(--header-bg);
  border-top: none;
  border-bottom: none;
}

.service_section {
  background-color: var(--header-bg);
  padding: 75px 0;
}
.text-white {
  color: var(--white) !important;
}

.offer_card {
  padding: 40px;
  border-radius: 24px;
  background-color: var(--header-bg);
  display: flex;
  flex-direction: column;
  gap: 40px;
  border: 1px solid var(--white);
  color: var(--white);
  cursor: pointer;
  /* max-width: 400px; */
  height: 440px;
  position: relative;
}

.offer_card:hover {
  background-color: var(--white);
  color: var(--black);
}

.offer_card:hover.offer_card h3 {
  color: var(--black);
}

.offer_card:hover.offer_card p {
  color: var(--black);
}

.offer_card h3 {
  color: var(--white);
  font-size: 28px;
  line-height: 32px;
  font-family: "jokker-bold";
  margin: 0;
}

.offer_card p {
  color: var(--white);
  font-size: 18px;
  line-height: 24px;
  font-family: "jokker-regular";
  margin: 0;
  min-height: 160px;
  max-width: 320px;
  line-height: 24px;
}

.offer_card svg {
  font-size: 50px;
  position: absolute;
  bottom: 40px;
  left: 20px;
}
.offer_card:nth-child(3) h3 {
  max-width: 100px;
}
.offer_card:hover .light_img {
  display: none;
}

.offer_card:hover .dark_img {
  display: block;
}
.insurance_marketplace_box {
  position: absolute;
  height: calc(100% - 100px);
  width: calc(100% - 160px);
  background-color: var(--white);
  align-items: center;
  left: 20px;
  bottom: 0;
  z-index: 3;
  border-radius: 24px;
  padding: 40px 70px;
  display: flex;
}

.insurance_marketplace_box p {
  font-size: 18px;
  line-height: 24px;
  color: var(--header-bg);
  font-family: "jokker-regular";
  margin-top: 0px;
}

.insurance_marketplace_box .devider {
  height: 100%;
  width: 2px;
  background-color: var(--header-bg);
  opacity: 0.2;
  margin: 0px 75px 0px 75px;
}

.insurance_marketplace svg {
  font-size: 50px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  transform: rotate(180deg);
}
.dark_img {
  display: none;
}
.userInfo.header-avatar .userImg {
  /* background-color: var(--dashboard-bg); */
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px !important;
}
.header-avatar .userImg{
  height: 30px;
    width: 30px;
}
.Insurance_Marketplace {
  padding: 75px 0;
}
.values_card:nth-child(2) > div,
.values_card:nth-child(5) > div {
  margin: 0 30px;
}
.largeiconcard img{
  width: 201px;
  height: 200px;
}
.custombtn {
  font-family: "jokker-bold" !important;
  color: var(--header-bg) !important;
  background-color: transparent !important;
}

.custombtn:hover {
  text-decoration: underline;
}
.Insurance_Marketplace_card {
  height: 204px;
  border-radius: 20px;
  padding: 0px;
  transition: 0.5s;
  margin-top: 0 !important;
  /* width: 295px; */
  width: calc(100% - 70px);
  /* margin-bottom: 20px; */
}

.Insurance_Marketplace_card img {
  margin-top: 33px;
  margin-left: 33px;
}

.Insurance_Marketplace_card h4 {
  opacity: 0;
  transition: 0.5s;
  margin: 0 33px;
  margin-bottom: 33px !important;
}

.Insurance_Marketplace_card h2,
.Insurance_Marketplace_card p {
  margin: 0 33px;
}

.insurance_marketplace_text {
  /* margin: 25px 0; */
  /* position: relative; */
}

.insurance_marketplace {
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 201px;
}

.insurance_marketplace_text h3 {
  color: var(--header-bg);
  font-size: 32px;
  line-height: 38px;
  font-family: "jokker-bold";
  margin: 0;
  margin-bottom: 10px;
}
.learnMoreModel {
  width: 60%;
  /* height: 600px; */
  position: absolute;
  background-color: var(--dashboard-bg);
  border-radius: 10px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  overflow: hidden;
  padding: 50px 30px;
}
.learnMoreModel:focus-visible {
  outline: none;
}
.contentTitleBox {
  width: 201px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contentTitleBox h2 {
  font-family: "jokker-bold";
  font-size: 24px;
  color: var(--black);
  margin: 0;
  padding: 0;
}
.contentTitleBox h3 {
  font-family: "jokker-regular";
  color: var(--black);
  margin-top: 10px;
  padding: 0;
  font-size: 18px;
}
.contentBox h2 {
  font-family: "jokker-bold";
  font-size: 24px;
  color: var(--black);
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.contentBox h3 {
  font-family: "jokker-regular";
  color: var(--black);
  margin-top: 10px;
  padding: 0;
  font-size: 18px;
}
.contentBox {
  width: 60%;
  padding: 0 30px;
  max-height: 400px;
  overflow: auto;
}
.deviders {
  width: calc(100% - 201px);
  position: relative;
  margin-left: 30px;
}
.deviders .contentBox{
  width: calc(100% - 90px);
  padding-left: 60px;
  padding-right: 60px;
}
.deviders::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: var(--header-bg);
  opacity: 0.2;
  top: 0;
  left: 0px;
  z-index: 2;
}
.contentBox p {
  font-family: "jokker-regular";
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--black);
  margin-top: 0;
  line-height: 28px;
}
.contentTitleBox p {
  font-family: "jokker-regular";
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--black);
  line-height: 28px;
}
.contentBox li {
  font-family: "jokker-regular";
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--black);
  line-height: 28px;
  margin-bottom: 10px;
  list-style: disc;
}
.join_section {
  background-color: var(--dashboard-bg);
  padding: 75px 0;
  /* padding-top: 0; */
}
.pr-75 {
  padding-right: 75px;
}
.partner {
  padding: 100px 0;
  background-color: var(--dashboard-bg);
}
.partner_card {
  background-color: var(--white);
  border-radius: 20px;
  min-height: 199px;
  transition: 0.5s;
  box-shadow: 0px 6px 12px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner_card:hover {
  transform: translateY(-10px);
}

.partner_card_image {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner_card_image img {
  height: 100%;
  width: auto;
  max-width: 250px;
  object-fit: contain;
}
.team_desc .p_tag {
  max-width: 295px;
}
.team_sub_desc .p_tag {
  max-width: 381px;
}

.team_sub_desc h2 {
  max-width: 265px;
}
.team_sub_desc .p_tag {
  max-width: 381px;
}

.team_sub_desc h2 {
  max-width: 265px;
}
.p_tag {
  font-family: "jokker-regular";
  font-size: 18px;
  margin: 0px;
  color: var(--header-bg);
  line-height: 24px;
}
.card_title {
  font-family: "jokker-bold";
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--header-bg);
}
.text-center {
  text-align: center;
}
.mt-30 {
  margin-top: 30px;
}
.p-30-40 {
  padding: 30px 40px !important;
}
.contact_section_main {
  position: relative;
  background-color: var(--white);
}

.contact_section_main::before {
  height: 100%;
  width: 50%;
  background-color: var(--header-bg);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.contact_section {
  background-color: var(--white);
  display: flex;
  position: relative;
  z-index: 1;
}
.promis_section {
  background-color: var(--header-bg);
  padding: 67px 0px 0px 0px;
  width: 50%;
}
.promis_section p {
  max-width: 85%;
}
.form_section {
  padding: 67px 0px 67px 67px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: var(--white);
}
.popupBg .form_section{
  width: 45%;
}
.form_fild p {
  color: var(--header-bg);
  width: 100%;
  font-size: 16px;
  font-family: "jokker-regular";
  margin: 0;
}
.error_fields p {
  color: var(--danger-red) !important;
}
.error_fields input {
  border: 1px solid var(--danger-red) !important;
}
.responsiveOfferModal {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-color: red;
  display: none;
}
.form_fild input {
  color: var(--header-bg);
  width: 100%;
  font-size: 16px;
  font-family: "jokker-regular";
  margin: 0;
  /* height: 40px; */
  width: calc(100% - 28px);
  border: unset;
  /* border: 1px solid var(--header-bg); */
  border-style: solid !important;
  border-width: 1px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
  padding: 14px 12px;
  margin-top: 10px;
  opacity: 1 !important;
}
.form_fild .select-box-landing input {
  width: 100% !important;
  margin-top: 0 !important;
}
.form_fild .select-box-landing .MuiInputBase-input {
  height: 48px !important;
}
.form_fild .select-box-landing svg {
  z-index: 1;
}
.form_fild input:focus,
.form_fild input:focus-visible,
.form_fild textarea:focus,
.form_fild textarea:focus-visible {
  outline: unset;
  border-color: rgb(9, 20, 58) !important;
}

.form_fild textarea {
  color: var(--header-bg);
  width: 100%;
  font-size: 16px;
  font-family: "jokker-regular";
  margin: 0;
  width: calc(100% - 28px);
  border: unset;
  border: 1px solid var(--header-bg);
  border-style: solid !important;
  border-width: 1px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
  padding: 16.5px 14px;
  margin-top: 10px;
  opacity: 1 !important;
}

.form_fild textarea:focus {
  outline: unset;
}
.select-box-landing {
  /* font-size: 16px !important;
  font-family: "jokker-regular";
  color: var(--black) !important;
  opacity: 0.5;
  padding: 12px 14px !important;
  margin-top: 10px !important;
  width: calc(100% - 28px) !important;
  border-radius: 4px !important; 
  border: 1px solid rgb(9, 20, 58) !important; */
}
.select-box-default {
  padding: 0 !important;
}
.submit_button {
  padding: 14px 40px;
  background-color: var(--yellow);
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-medium";
  border: unset;
  cursor: pointer;
  width: fit-content;
  border-radius: 8px;
}
.footer_section {
  /* height: 97px; */
  padding: 10px 0px;
  display: flex;
  align-items: center;
  background-color: var(--turquoise);
}
.footer_details {
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}
.insurance-field-error{
  position: absolute;
  top: 56px;
}
.mt-10{
  margin-top: 10px !important;
}
.footer_details p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: "jokker-medium";
}
.partner_heading p {
  font-size: 20px;
  max-width: 557px;
  margin: auto;
}
.partner_box_main {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.partner_box {
  margin-top: 0px;
  padding-top: 20px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #dedfe4;
  padding-bottom: 20px;
}
.flexibleCard{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.join_section h1 {
  margin-bottom: 70px !important;
}
.partner_box:nth-child(2),
.partner_box:nth-child(5) {
  align-items: center;
  border-left: 1px solid #dedfe4;
  border-right: 1px solid #dedfe4;
}
.partner_box:nth-child(5),
.partner_box:nth-child(4) {
  border-bottom: unset;
  justify-content: end;
}
.partner_box:nth-child(3) {
  align-items: flex-end;
}
.partner_box h2 {
  height: 50px;
}

.partner_box h4 {
  width: fit-content;
}
.partner_box p {
  min-height: 111px;
}
.how_work_section {
  padding: 75px 0;
  background-color: var(--dashboard-bg);
}
.how_work_section_p {
  font-size: 20px;
  line-height: 28px;
  /* max-width: 505px; */
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.how_work_section_dark_box {
  background-color: var(--header-bg);
  padding: 30px 30px 0px 30px;
  background-position: bottom;
  border-radius: 24px;
  background-repeat: repeat-x;
  height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(../assets/images/background-pattern.png);
}
.how_work_section_dark_box img {
  width: fit-content;
  height: 224px;
}
.how_work_section_dark_box h4 {
  font-family: "jokker-bold" !important;
  font-size: 24px;
  color: var(--turquoise);
  margin-top: 0px;
  margin-bottom: 20px;
}
.how_work_section_dark_box p {
  font-family: "jokker-regular" !important;
  font-size: 18px;
  color: var(--white);
  margin: 0px;
  padding: 0px;
  min-height: 60px;
  line-height: 24px;
}
.how_work_section_box_main {
  padding: 35px 25px;
  /* height: unset; */
  margin: 0px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.how_work_section_box_main h3 {
  margin: 0px !important;
  padding: 0;
  font-size: 32px;
  font-family: "jokker-bold";
  margin-bottom: 30px !important;
  line-height: 38px;
}
.how_work_section_box_main p {
  margin: 0px !important;
  padding: 0;
  line-height: 24px;
}
.landing-page-modal .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  /* background-color: rgba(9, 20, 58, 0.9) !important; */
}
.close-icon {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 1;
}
.contentTitleBox button {
  padding: 16px 32px;
  font-family: "jokker-semibold";
  font-size: 18px;
}

.Insurance_Marketplace_card_new {
  padding: 30px;
  min-height: 210px;
}
.Insurance_Marketplace_card_new img {
  margin-top: 0px;
  margin-left: 0px;
}
.Insurance_Marketplace_card_new h2,
.Insurance_Marketplace_card_new p,
.Insurance_Marketplace_card_new h4 {
  margin-left: 0px;
  margin-right: 0px;
}
.Insurance_Marketplace_card_new h4 {
  margin-bottom: 0px !important;
}
.Insurance_Marketplace_card_new {
  height: unset;
  width: unset;
}
.h100 {
  height: 100%;
}
.static_page {
  padding: 100px 0;
}
.static_page h1 {
  color: var(--header-bg);
  font-family: "bunch-bold";
  font-size: 3.1rem;
  letter-spacing: 0px;
  margin-top: 0px;
  text-align: center;
}
.licenses_box {
  color: var(--turquoise);
  font-size: 1.5rem;
  padding: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: var(--header-bg);
  border-radius: 22px;
}
.static_page b {
  font-family: "jokker-bold";
  font-size: 20px;
}
.static_page p {
  font-family: "jokker-regular";
  font-size: 1.25rem;
  line-height: 2rem;
}
.static_page li {
  font-family: "jokker-regular";
  font-size: 1.25rem;
  line-height: 2rem;
  list-style: auto;
}

.select-box-landing .MuiInput-input:focus {
  background-color: transparent;
}
.select-box-landing
  .contact-select-box:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: 1px solid var(--header-bg);
}
.select-box-landing .contact-select-box:before {
  border-bottom: none !important;
}
.select-box-landing .contact-select-box:after {
  border-bottom: unset;
}
.Insurance_Marketplace_card p {
  height: 48px;
}
.text-align-center{
  text-align: center;
}
.yolohimg{
  transform: translateX(-50%);
  position: absolute;
  width: calc(100% - 140px);
  /* width: 95%; */
  left: 50%;
  top: 20px;
}
@media (min-width: 1024px) {
  .Insurance_Marketplace_card_new:hover img {
    filter: brightness(0) invert(1);
  }
  .Insurance_Marketplace_card_new:hover h2 {
    color: var(--white);
  }
  .Insurance_Marketplace_card:hover.Insurance_Marketplace_card h4 {
    opacity: 1 !important;
    color: var(--white);
  }
}
@media (min-width: 1800px) {
  .hero_text h1 {
    line-height: 1;
  }
  .MuiGrid-grid-md-4:not(.notfourcard .MuiGrid-grid-md-4) {
    flex-basis: 25% !important;
  }
  .hero_main {
    /* padding-top: 250px !important;  */
  }
  .yolohimg {
    /* top: -220px !important; */
  }
  .notFoundContain img {
    width: 73% !important;
    margin: 20px auto !important;
  }
  .innercontainer {
    max-width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .how_work_section_p {
    margin-left: auto;
  }
  .Insurance_Marketplace_card {
    width: calc(100% - 100px);
    /* height: 175px ; */
  }
  .how_work_section_box_main {
    width: calc(100% - 115px);
  }

  .values_card p {
    max-width: 100%;
  }
  .offer_card {
    height: 360px;
  }
  .values_card {
    padding-left: 50px;
    padding-right: 50px;
  }
  .partner_box:first-child {
    padding-left: 0;
  }
  .partner_box:nth-child(4) {
    padding-left: 0;
  }
  .partner_box_main .values_card:nth-child(3) h2 {
    height: 56px;
  }
  .about_card {
    min-height: 200px;
  }
  .how_work_section_dark_box {
    height: 375px;
  }
}

@media only screen and (max-width: 1799.98px) {
  .res-modal-title {
    font-size: 28px;
  }
  .offer_card h3 {
    height: 68px;
  }
}

@media only screen and (max-width: 1499px) {
  .main_modal_bg #modal-modal-title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1299px) {
  .partner_card {
    min-height: 125px;
  }
  .partner_card_image img {
    height: 75%;
    max-width: 160px;
  }
  .headerLink a,
  .headerLink .link {
    font-size: 16px;
  }
  .hero_text h1 {
    font-size: 40px;
    line-height: 1;
  }
  .hero_text p {
    font-size: 16px;
    line-height: 24px;
  }
  .main_heading {
    font-size: 40px;
  }
  .join_section h1 {
    margin-bottom: 50px !important;
  }
  .values_card > div {
    max-width: 264px;
  }
  .values_card:nth-child(2) > div {
    margin: 0 45px;
  }
  .how_work_section_p,
  .aboutText p,
  .lenders_servicers_Text p,
  .partner_heading p {
    font-size: 17px;
    line-height: 24px;
  }
  .how_work_section_dark_box h4,
  .values_card h2,
  .overview_card_heading h2,
  .about_card h3 {
    font-size: 20px;
  }
 
  .contentBox p,
  .contentBox li {
    font-size: 14px;
  }
  .contentBox li{
    margin-bottom: 0px;
  }
  .contentBox p,
  .contentTitleBox p {
    font-size: 14px;
  }
  .contentBox h2 {
    font-size: 20px;
  }
  .how_work_section_dark_box p,
  .about_card p,
  .p_tag {
    font-size: 15px;
    line-height: 20px;
  }
  .overview_card_number h1 {
    font-size: 52px;
  }
  .css-5aijib {
    padding: 30px !important;
  }
  .how_work_section_box_main h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px !important;
  }
  .banner-explore-btn {
    width: 160px;
    height: 46px;
    font-size: 15px;
  }
  .overview_table_text {
    font-size: 15px !important;
  }
  .about_card {
    min-height: 215px;
  }

  .offer_card h3 {
    font-size: 26px;
    line-height: 34px;
  }
  .offer_card p,
  .insurance_marketplace_box p,
  .values_card p,
  .image_col_bg p,
  .image_col_bg_step_text,
  .insurance_box p,
  .Insurance_Marketplace_card h4 {
    font-size: 15px !important;
    line-height: 20px;
  }

  .Insurance_Marketplace_card_new {
    padding: 25px;
  }
  .loginBtn,
  .thirdHeader a {
    font-size: 18px;
  }
  .profile-title,
  .notification_contain h3,
  .notification_contain p,
  .notification_right {
    font-size: 15px;
  }
  .thirdHeader {
    height: 65px;
  }
  .layout .pt-40 h1 {
    font-size: 28px;
  }
  .convertAgentContain {
    gap: 25px;
  }
  .notification_contain h3 {
    margin-bottom: 6px;
  }
  .profile-input-section {
    margin-top: 16px;
    border-radius: 16px;
  }
  .notification_left img {
    height: 35px;
    width: 35px;
  }
  .static_page h1 {
    font-size: 2.5rem;
  }
  .static_page {
    padding: 75px 0;
  }
  .licenses_box {
    gap: 15px;
    padding: 20px;
    font-size: 22px;
  }
  .notification_card {
    padding: 25px 30px;
  }
  .thirdHeader a {
    padding: 11px;
    font-size: 15px;
  }
  .image_col_bg h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .insurance_flex_box,
  .main_modal {
    gap: 30px;
  }
  .insurance_box div {
    padding: 18px;
  }
  .insurance_box img img {
    height: 40px;
  }
 
  .popupBg .form_section{
    gap: 15px;
  }
  .submit_button{
    padding: 12px 20px;
    font-size: 14px;
  }
  .quote-request {
    height: 46px;
  }
  .active-lead-btn button {
    font-size: 15px !important;
  }
  .sideBarTopMenu span,
  .sideBarBottomMenu li {
    padding: 22px !important;
  }
  .verification-status {
    font-size: 14px;
  }
  .overview_insurance_card h3,
  .overview_insurance_card_policy_inner p {
    font-size: 15px;
  }
  .overview_insurance_card_policy_inner_text_bold,
  .overview_insurance_card_download {
    font-size: 15px !important;
  }
  .overview_insurance_card_download {
    height: 40px;
  }
 
  .learnMoreModel{
    padding: 25px 15px;
  }
  
  .contentTitleBox{
    padding: 0 15px;
  }
  .deviders .contentBox{
    padding: 0 30px;
    width: calc(100% - 45px);
  }
  .deviders{
    margin-left: 15px;
  }
  .coverages_text {
    font-size: 20px;
  }
  .about_image {
    padding-right: 10%;
  }
  .offer_card {
    height: 380px;
    gap: 25px;
  }
  .how_work_section_dark_box {
    height: 350px;
  }
  .footer_details div,
  .footer_details p {
    font-size: 15px !important;
  }
  .Insurance_Marketplace_card {
    height: 175px !important;
  }
  .close-icon{
    top: 20px;
    right: 20px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .partner_box:nth-child(3),
  .partner_box:nth-child(2),
  .partner_box:nth-child(5) {
    align-items: unset !important;
  }

  .responsiveOfferModal {
    display: block;
    opacity: 0;
  }
  .hero_section {
    /* background-size: 95%; */
  }
  .offer_card h3 {
    height: 68px;
  }
  .values_card > div {
    margin: 0 45px !important;
  }
  .values_card p {
    max-width: unset;
    min-height: unset !important;
  }
  .pr-75,
  .pl-75 {
    padding: 0 !important;
  }
  .border-l-r {
    border: unset !important;
  }
  .offer_card {
    max-width: unset;
    height: 290px
  }

  .offer_card p {
    max-width: unset;
  }

  .offer_card svg {
    display: none;
  }
  .insurance_marketplace_box {
    display: none;
  }
  .team_desc .p_tag {
    max-width: unset;
  }
  .partner_box_main {
    grid-template-columns: auto auto;
  }
  .partner_box {
    padding: 20px;
    justify-content: start !important;
  }
  .partner_box:nth-child(2) {
    border-right: unset;
  }
  .partner_box:nth-child(3) {
    border-right: 1px solid #dedfe4;
  }
  .partner_box:nth-child(4) {
    border-bottom: 1px solid #dedfe4;
    justify-content: start;
  }
  .partner_box:nth-child(5) {
    border-left: unset;
    justify-content: start;
  }
}
@media only screen and (max-width: 1055px) {
  .contact_section {
    display: block;
  }
  .promis_section {
    width: auto;
  
  }
  .form_section{
    padding: 40px;
  }
  .promis_section {
    padding: 12px 0 !important;
    padding-bottom: 0 !important;
  }
  .form_section {
    width: auto;
  }
  .contact_section {
    background-color: unset;
  }
}
@media only screen and (max-width: 1024px) {
  .custombtn{display: none;}
  .contentBox{
    padding: 0;
  }
  .contentTitleBox h2 {
    margin-bottom: 12px;
  }
  .contact_section_main::before {
    width: 100%;
    height: 200px;
  }
  .contentBox{
    padding-right: 10px;
  }
  .promis_section img {
    display: none !important;
  }

  .promis_section {
    padding: 6px 0;
  }
  .learnMoreModel {
    flex-direction: column;
    padding: 40px 30px;
  }
  .deviders{
    width: 100%;
    margin-left: 0;
  }
  .deviders::after{
    content: none;
  }
  .contentTitleBox{
    padding: 0;
  }
  .deviders .contentBox{
    width: 100%;
  }
  .largeiconcard img{
    height: 40px;
    width: unset;
  }
  .deviders .contentBox{
    padding: 0 ;
  }
  .contentTitleBox {
    width: -webkit-fill-available;
    width: -moz-available;
    padding-bottom: 0px;
  }
  .contentBox {
    width: -webkit-fill-available;
    width: -moz-available;
    padding-top: 0px;
    padding-right: 10px;
  }
  .contentTitleBox .submit_button {
    margin-bottom: 12px;
  }
  .css-1dcp0mw-MuiGrid-root > .MuiGrid-item {
    padding-left: 20px !important;
  }
  .Insurance_Marketplace_card.how_work_section_box_main {
    margin-left: 0 !important;
    width: calc(100% - 20px) !important;
    padding: 0;
  }
}
@media only screen and (max-width: 992px) {
  .hero_image_main {
    margin: auto;
    display: block;
  }
  .lenders_servicers_image img{
    width: 90%;
  }
  .main_heading {
    font-size: 30px !important;
  }
  .about_image {
    padding-right: 10%;
    justify-content: end !important;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .about_card {
    width: auto;
  }

  .values_card h4 {
    margin-top: 10px !important;
  }
  .offer_card p {
    min-height: unset;
  }
  .Insurance_Marketplace_card h4 {
    opacity: 1;
  }

  .Insurance_Marketplace_card p {
    margin-bottom: 10px !important;
  }

  .Insurance_Marketplace_card {
    min-height: unset;
    padding: 10px 5px;
    border-bottom: 1px solid var(--header-bg);
    border-radius: 0px;
  }
  .contact_section_main::before {
    width: 100%;
    height: 187px;
  }

  .promis_section,
  .form_section {
    padding: 20px;
  }

  .promis_section p {
    margin-top: 15px !important;
  }

  .partner_card {
    min-height: 125px;
  }
}
@media only screen and (max-width: 899.98px) {
  .lenders_servicers_content{
    padding-right: 40px !important;
  }
  .lenders_servicers_image{
    justify-content: center !important;
    overflow: hidden;
    margin-bottom: -50px !important;
  }
  .lenders_servicers_image img{
    margin-right: 0px !important;
    rotate: 90deg;
    height: 50%;
    margin-bottom: -55px;
    width: auto !important;
  }
  .Insurance_Marketplace_card {
    width: 95% !important;
  }
  .promis_section {
    padding: 25px 16px !important;
  }

  .sideBarTopMenu span,
  .sideBarBottomMenu li {
    padding: 18px !important;
  }
  .aboutSection .MuiGrid-root .about_image.MuiGrid-root:nth-child(2) img {
    display: none;
  }
  .about_card {
    min-height: unset;
  }
  .promis_section {
    text-align: center;
  }
  .promis_section p {
    margin: 0 auto;
  }
  .hero_main {
    /* padding-top: 200px !important; */
  }
  .yolohimg {
    /* top: -170px; */
  }
  .hero_image_main {
    width: 50% !important;
  }

  .about_card {
    max-width: unset;
  }
  .values_card p {
    max-width: unset;
  }
  .Insurance_Marketplace_card {
    height: unset;
    width: unset;
    padding: 30px 0px;
  }

  .team_sub_desc h2 {
    max-width: unset;
  }
  .aboutText p {
    max-width: unset;
  }
  /* .values_card:nth-child(2) div  */
  .values_card > div
  {
    margin: 0 15px !important;
  }
  .hero_main .MuiGrid-root {
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .footer_section {
    height: unset;
    padding: 20px 0;
  }
  .Insurance_Marketplace_card p {
    height: 40px;
  }
  .hero_main .MuiGrid-root {
    width: 100%;
  }
  .css-14ubjaw-MuiGrid-root {
    padding-top: 50px !important;
  }
}
@media only screen and (max-width: 600px) {
  .partner_box h2 {
    height: unset;
  }
  .values_card > div {
    margin: 0 !important;
    max-width: 100%;
  }
  .hero_main {
    /* padding-top: 150px !important; */
    min-height: unset;
  }

  .yolohimg {
    /* top: -120px; */
  }
  .hero_image_main {
    margin: auto;
    display: block;
  }
  .partner_box_main {
    grid-template-columns: auto;
  }
  .partner_box {
    padding: 20px;
    min-height: 183px;
    padding: 20px 0px;
  }
  .partner_box:nth-child(2) {
    border-right: unset;
    border-left: unset;
    border-bottom: 1px solid #dedfe4;
  }
  .partner_box:nth-child(3) {
    border-right: unset;
    border-left: unset;
    border-bottom: 1px solid #dedfe4;
  }
  .partner_box:nth-child(4) {
    border-bottom: 1px solid #dedfe4;
    justify-content: start;
    border-right: unset;
    border-left: unset;
  }
  .partner_box:nth-child(5) {
    border-left: unset;
    justify-content: start;
    border-right: unset;
    border-left: unset;
  }
  .contentTitleBox {
    width: -webkit-fill-available;
    width: -moz-available;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .contentBox {
    width: -webkit-fill-available;
    width: -moz-available;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .learnMoreModel {
    width: 85%;
    padding: 16px;
  }
  .Insurance_Marketplace_card p {
    height: unset;
  }
  .Insurance_Marketplace_card {
    height: unset !important;
  }
  .close-icon{
    top: 16px;
    right: 16px;
  }
}
@media only screen and (max-width: 484px) {
  .contact_section_main::before {
    content: unset;
  }
  .promis_section {
    margin-left: -16px;
    margin-right: -16px;
  }
}
@media only screen and (max-width: 425px) {
  .hero_image_main {
    max-width: 300px;
  }
}
