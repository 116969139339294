* {
  /* font-family: "Nunito", sans-serif; */
  font-family: "Jokker-Regular";
  letter-spacing: 0.25px !important;
}

.css-5aijib,
.drafts-card,
.card-active,
.purchased-card,
.closed-card,
.other-status-card,
.overview_insurance_card,
.id_box ,
.insurance-details,.previewSteps,.shadowbox,.insurance-header,.profile-input-section,.sticky_footer,.boxContainer,.leadsDetailsAccordion{
  box-shadow: 0px 6px 12px #00000029 !important;
}
.secondHeader {
  /* display: none; */
}
.notification-menu{
  position: relative;
}
.notification-red-dot{
  position: absolute;
  top: -2px;
  right: 1px;
  height: 12px;
  width: 12px;
  background-color: #f06363;
  border-radius: 50%;
}
@media (min-width: 576px) {
  .container_width {
    /* max-width: 540px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 768px) {
  .container_width {
    /* max-width: 720px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 992px) {
  .container_width {
    /* max-width: 880px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1024px) {
  .Insurance_Marketplace_card:hover {
    background-color: #091539;
    box-shadow: 0px 6px 12px #00000008;
  }
  .Insurance_Marketplace_card:hover h3,
  .Insurance_Marketplace_card:hover p {
    color: var(--white);
  }
}
@media (min-width: 1200px) {
  .container_width {
    /* max-width: 1040px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1300px) {
  .container_width {
    /* max-width: 1100px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1400px) {
  .container_width {
    /* max-width: 1240px !important; */
    padding: 0 40px;
  }
}
@media (min-width: 1800px) {
  .container_width {
    /* max-width: 1560px !important; */
      padding: 0 40px;
  }
  .partner_box p {
    min-height: 91px;
  }

  .hero_main {
    /* padding-top: 250px !important; */
  }
  .notFoundContain img {
    width: 73% !important;
    margin: 20px auto !important;
  }
}
@media (min-width: 1921px) {
  .container_width {
    max-width: 1560px !important;
      /* padding: 0 40px; */
  }
}

.layout {
  position: relative;
  background-color: var(--dashboard-bg);
  min-height: calc(100vh - 300px);
}
.bglight {
  background-color: var(--white) !important;
}
.heroSection {
  background-color: var(--white);
  height: 689px;
}

.createAccount {
  background-color: var(--dashboard-bg);
}

.heroInfo {
  display: flex;
  align-items: center;
  height: 689px;
}

.heroText {
  padding: 10px;
}

.heroText h2,
.heroText h5 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}

.preview-header-main {
  background-color: var(--white);
}

.heroText h5 {
  font-size: 16px;
  color: var(--ceramic-gray);
  margin-bottom: 20px;
}

.overview_card_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview_card_heading h2 {
  margin: 0;
  padding: 0;
  color: var(--turquoise);
  font-family: "jokker-bold";
}

.overview_card {
  cursor: pointer;
  padding: 30px;
  background-color: var(--header-bg);
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.heroText h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.slick-dots li {
  margin: 0 !important;
}
.thirdHeader a,
.thirdHeader .notificationalert {
  color: var(--grey);
  font-size: 17px;
  font-family: "jokker-medium";
  letter-spacing: 0px;
  color: var(--header-bg);
  opacity: 1;
  padding: 15px;
  cursor: pointer;
}
.notificationalert .MuiBadge-badge{
  color: var(--header-bg) !important;
  background-color: var(--yellow) !important;
  width: 25px !important;
  height: 25px !important;top: 5px !important;
  right: 5px !important;
  border-radius: 50% !important;
  

}
.loader-contain {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(9, 20, 58, 0.3);
  /* background-color: rgba(255, 255, 255, 0.73); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.thirdHeader .active,.thirdHeader .active:hover,.activeNotification:hover{
  background-color: var(--header-bg) !important;
  border-radius: 12px;
  color: var(--white) !important;
}
.activeNotification {
  background-color: var(--header-bg);
  border-radius: 12px;
  color: var(--white) !important;
}
.notification-read:hover {
  background-color: var(--white) !important;
}
.overview_title {
  color: var(--header-bg);
  margin: 0;
  padding: 0;
  font-family: "jokker-bold";
  font-size: 24px;
}
.underlinenotification{
  position: relative;
  margin-bottom: 20px !important;
}
.underlinenotification::after{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: radial-gradient(50% 50% at 50% 50%, var(--header-bg) 0%, rgba(191, 2, 2, 0) 100%);
  bottom: -15px;
}
.ghostMessage {
  font-size: 20px;
  font-family: "jokker-regular";
  color: #09143a;
  font-weight: bold;
}

.overview_table_heading {
  color: var(--header-link) !important;
  font-size: 18px !important;
  font-family: "jokker-regular" !important;
}

.overview_table_text {
  color: var(--header-bg) !important;
  font-size: 18px !important;
  font-family: "jokker-medium" !important;
}

.overview_table_btn {
  cursor: pointer;
}

.view_button {
  background-color: var(--yellow) !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  color: var(--header-bg) !important;
  font-size: 18px !important;
  font-family: "jokker-medium" !important;
  text-transform: none !important;
}

.flex_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: " " !important;
  text-align: center;
  background: var(--ivory);
  opacity: 1 !important;
  color: var(--black);
}

.slick-dots li.slick-active button:before {
  height: 20px;
  width: 10px;
  background-color: var(--black);
  border-radius: 10px;
}

.heroSection .slick-dots {
  bottom: 100px !important;
  width: unset !important;
  margin: unset !important;
  list-style: unset !important;
  text-align: unset !important;
}

.heroText {
  color: var(--light-black);
}
.positionbtn{
  position: absolute;
  bottom: 30px;
  right: 50px;
}
.heroImage img {
  margin: auto;
  display: block;
}

.align-items-center {
  align-items: center;
}
.flex-direction-column{
  flex-direction: column;
}
.gap-10 {
  gap: 10px;
}

.image-section {
  background-color: var(--header-bg);
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.w-50 {
  width: 50% !important;
}

.logo-create {
  margin: auto;
  display: block;
  margin-top: 120px;
}

.MuiDrawer-paper {
  background-color: #09143a !important;
}

.illu-create {
  margin: auto;
  display: block;
  height: 413px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.illu {
  width: 301px;
}
.roundShapeNew {
  position: absolute;
  height: 470px;
  width: 470px;
  background-color: var(--yellow);
  opacity: 0.1;
  top: -80px;
  right: -10%;
  border-radius: 100%;
}

.create-heading h1 {
  color: var(--header-bg);
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.form-section {
  padding: 40px;
  width: 40%;
}
.create-field-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0;
}

.create-field {
  position: relative;
}

.input-img {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 20px;
}

.date-birth {
  top: 50%;
}

.align-right {
  margin: unset;
  margin-left: auto !important;
}

.side-bar {
  width: 325px;
  height: -webkit-fill-available;
  height: -moz-available;
  background-color: var(--white);
  position: fixed;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 40px var(--carbon-black);
  background-color: var(--dashboard-bg);
}

.side-bar-top .active {
  box-shadow: 0px 0px 16px var(--carbon-black);
}

/* .mainSideBar .active {
  position: relative;
  background-color: var(--white);
  padding: 11px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */
/* .mainSideBar .active::before {
  content: " ";
  height: 100%;
  width: 3px;
  background-color: var(--yellow);
  position: absolute;
  left: 0;
  top: 0;
} */
.side-bar-link {
  padding: 20px 30px;
  font-size: 20px;
  position: relative;
  display: flex;
  gap: 25px;
  cursor: pointer;
}
.side-bar-link p {
  margin: 0;
}

.insurance_flex_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 30px;
}

.side-bar-icon {
  height: 30px;
  width: 30px;
  background-color: var(--off-white);
}

.activity-dashboard {
  /* margin-left: 325px; */
  width: 100%;
  background-color: var(--dashboard-bg);
  /* height: 100vh; */
}

.overview_insurance_card_policy_inner p {
  margin: 0;
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-medium";
}

.overview_insurance_card_policy_inner_text {
  opacity: 50%;
  font-family: "jokker-semibold";
}

.text-end {
  text-align: end;
}

.overview_insurance_card_policy_inner_text_bold {
  font-family: "bunch-bold" !important;
  font-size: 28px !important;
}

.overview_insurance_card_download {
  border: 1px solid var(--header-bg) !important;
  border-radius: 8px !important;
  background-color: var(--white) !important;
  color: var(--header-bg);
  font-family: "jokker-medium" !important;
  font-size: 18px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.overview_insurance_card_policy_inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 30px 0;
}

.overview_insurance_card_policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.overview_insurance_card {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 6px 12px #00000008;
  padding: 20px;
}

.overview_insurance_card h3 {
  font-size: 18px;
  font-family: "jokker-bold";
  color: var(--header-bg);
  margin: 0;
}

.aboutText {
  align-self: center;
}

.main-dashboard {
  display: flex;
}

.aboutSection {
  padding: 50px 0;
  /* background-color:  var(--off-white); */
}

/* Agent Enrollment */
.agentEnrollmentHeading {
  font-family: "bunch-Bold";
  color: #30ebf2;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.agentConsent {
  color: blue;
}

.boxContainer {
  padding: 3%;
  border-radius: 24px;
  background: #fff;
  /* width: 96%; */
  /* max-width: "1240px"; */
  margin: auto;
  margin-top: 20px;
}
.w-fit {
  width: -webkit-fill-available;
  width: -moz-available;
}
.preview-header-right-btn {
  display: flex;
  gap: 0px;
  background-color: #09143a33;
  border: 1px solid #09143a80;
  border-radius: 8px;
  padding: 4px;
}
.preview-header-right-btn button {
  background-color: transparent !important;
  width: 150px !important;
}
.preview-header-right-btn button.active {
  background-color: var(--header-bg) !important;
  color: var(--white) !important;
}
.footerNextBtn {
  color: #09143a;
  background-color: #ffcd00;
  border-radius: 12px;
  font-family: "jokker-Medium";
  font-size: 18px;
  width: 100px;
  border: none;
  height: 53px;
  cursor: "pointer";
}

.uploadEnrollDocs {
  font-family: "jokker-Regular";
  font-size: 18px;
}

.deleteIconEnrollment {
  cursor: pointer;
  z-index: 9;
}

.fieldsErrorMessage {
  color: #d72a2a;
  font-size: 0.75rem;
}

.errorMessageTerms {
  font-weight: bold;
  color: red;
  font-size: 20px;
}

/* Dashboard */
.ghostScreenDashboard {
  padding: 50px;
  margin-top: 40px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.ghostScreenDashboard img{
  width: 500px;
}
.dashboradTableMain {
  padding: 50px;
  margin-top: 40px;
  border-radius: 24px;
  background: #fff;
}

/* Update Profile */

.profileHeading {
  font-family: "jokker-Bold";
  font-size: 24px;
}

.profilePicDownload {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 25px;
}

.footerLink {
  display: flex;
  gap: 20px;
  margin: auto;
  width: fit-content;
  margin-top: 40px;
  margin-bottom: 40px;
}
.footerLink h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: var(--white);
}

.footerLink h3:hover {
  color: var(--orange);
}

.footerLink .active {
  color: var(--orange);
}

.footerSocial {
  height: 30px;
  width: 30px;
}

.bottomFooter {
  background-color: var(--header-bg);
  padding: 20px 0;
  margin: 0;
  text-align: center;
  border-top: 1px solid var(--white);
}

.bottomFooter p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  color: var(--white);
}

.footer h5 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  max-width: 730px;
  padding: 0;
  color: var(--white);
}

.productImg {
  margin: auto;
}

.d-flex {
  display: flex;
}

.productHeading {
  padding: 50px 0;
  background-color: #09143a;
}

.productHeading h1,
.productHeading p {
  margin: 0;
  padding: 0;
}

.productHeading p {
  font-size: 20px;
}

.footer {
  padding: 40px;
  text-align: center;
  background-color: var(--header-bg);
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0;
}

.activity-dashboard-header h1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.activity-dashboard-header {
  background-color: var(--white);
  padding-top: 44px;
  padding-left: 24px;
  padding-right: 24px;
}

.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: end;
}

.gap-20 {
  gap: 20px;
}

.search-section input {
  height: 40px;
  width: 325px;
  padding-left: 40px;
  border: none;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.search-section input:focus {
  outline: unset;
}

.align-items-center {
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.self-center {
  align-self: center;
}

.relative {
  position: relative;
}

.relative img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.grid-box {
  height: 40px;
  width: 40px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.grid-icon {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: auto;
  margin-top: 50%;
  width: fit-content;
  transform: translateY(-50%);
}

.icon-line1 {
  height: 3px;
  width: 24px;
  border-radius: 4px;
  border: 2px solid var(--light-black);
}

.icon-line2 {
  height: 3px;
  width: 13px;
  border-radius: 4px;
  background-color: var(--yellow);
  border: 2px solid var(--light-black);
}

.mt-44 {
  margin-top: 44px;
}

.gap-30 {
  gap: 30px;
}

.dashboard-card {
  width: 40%;
  border: 1px solid var(--cream);
  border-radius: 10px;
  height: 100px;
  padding: 24px;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 20px;
  background-color: var(--marble-white);
  cursor: pointer;
}

.card-main-dashboard {
  /* width: 100%; */
  /* flex-wrap: wrap; */
  width: 100%;
  flex-wrap: wrap;
  /* margin-top: 70px; */
  /* height: 100vh; */
  padding: 0 50px;
  box-sizing: border-box;
  /* margin-left: -70px; */
}

.dashboard-card::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--bottle-green);
}

.font-36 {
  font-size: 36px;
}

.icon-bg {
  width: 56px;
  height: 56px;
  background-color: var(--cape-white);
  border-radius: 100%;
}

.dashboard-card-text {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
  align-self: center;
}

.orange {
  background-color: var(--cream);
}

.orange h1 {
  color: var(--black);
}

.orange::before {
  background-color: var(--khamam-gray);
}

.yellow h1 {
  color: var(--turquoise);
}

.yellow::before {
  background-color: var(--danger-red);
}

.yellow {
  background-color: var(--carme-white);
}

.red h1 {
  color: var(--yellow);
}

.red::before {
  background-color: var(--light-yellow);
}

.red {
  background-color: var(--peach);
}

.side-bar-top .active::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: var(--orange);
}

.grid-box {
  display: none;
}

.addLicenseBtn {
  border: none;
  border-radius: 8px;
  background-color: #ffcd00;
  color: #09143a;
  font-family: "Jokker-Medium";
  font-size: 16px;
  width: 89px;
  height: 37px;
  cursor: pointer;
}

.stateAndProductHeading {
  font-size: 24px;
  font-family: "Jokker-Bold";
}
.stateAndProductHeading br,
.affiliationDetailHeading br {
  display: none;
}
.stateAndProductDivider {
  border: 1px solid #4b5764;
  width: 100%;
  margin-top: 40px;
  margin-left: 10px;
  opacity: 0.5;
}

.affiliationDetailHeading {
  font-size: 24px;
  font-family: "Jokker-Bold";
}

.yourPoliciesMainBox {
  background-color: white;
  /* width: 80%;
  max-width: 400px; */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 12px;
  transition: 0.5s;
  /* margin-top: 20px; */
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mb-0{
  margin-bottom:  0px !important;
}
/* .icon-bg-active-card {
  height: 60px;
  border-radius: 15px;
  background-color: var(--white) !important;
} */
.yourPoliciesMainBox:hover {
  position: relative;
  z-index: 1;
  transform: translateY(-10px);
}

.chatScreenQuotesCards {
  background-color: white;
  max-width: 400px;
  margin: 0px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 12px;
}

.nameAndLableOfPolicies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2;
  margin-top: 1px;
}

.userNameOfPolicy {
  font-size: 18px;
  font-family: "Jokker-Bold";
}

.policyStatus {
  background-color: #54c192;
  color: #09143a;
  border-radius: 8px;
  font-family: "jokker-Regular";
  font-size: 16px;
  width: 116px;
  height: 34px;
  text-align: center;
  margin: 8px;
  line-height: 35px;
}

.noStatus {
  background-color: #fff;
  color: #09143a;
  border-radius: 8px;
  font-family: "jokker-Regular";
  font-size: 16px;
  width: 116px;
  height: 34px;
  text-align: center;
  margin: 8px;
  line-height: 35px;
}

.notificationMain {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.customBoxNotification {
  display: flex;
  border-radius: 12px;
  background: #fff;
  margin: auto;
  margin-top: 30px;
  padding: 30px 40px;
  cursor: pointer;
  gap: 20px;
}

.customBoxNotification:hover {
  background-color: #30ebf2;
}

.notification-read {
  display: flex;
  padding: 25px 0vw 20px 0vw;
  border-radius: 12px;
  background: var(--dashboard-bg) !important;
  /* max-width: 1156px; */
  /* width: 100%; */
  padding: 30px 40px;
  margin-top: 30px;
  cursor: pointer;
  gap: 10px;
}

.customBoxNotification:last-child {
  /* margin-bottom: 20px; */
}

.markAsRead {
  /* margin-right: 150px; */
  margin-top: 25px;
  font-family: "Jokker-Medium";
  font-size: 16px;
  cursor: pointer;
}

.insuranceMarketIcon {
  background-color: #09143a;
  color: #ffffff;
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  width: 40px;
}

.mainInsurance {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  /* margin-left: 20px; */
}

.timeStemp {
  color: #4b5764;
  font-family: "jokker-Medium";
  font-size: 18px;
}

.notification_dec {
  width: 88%;
  margin-top: 5px;
  line-height: 22px;
}

.container_loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadManageMoreContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadLoadMore {
  background-color: #ffcd00;
  color: #09143a;
  font-size: 18px;
  font-family: "jokker-Medium";
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  width: 126px;
  border: none;
  padding: 10px;
}
.chat-header-right button {
  font-size: 18px !important;
  line-height: 20px !important;
  font-family: "jokker-semibold" !important;
  padding: 8px 16px !important;
  background-color: transparent !important;
  width: max-content !important;
}
.loadMoreBtn {
  background-color: #ffcd00;
  color: #09143a;
  font-size: 18px;
  font-family: "jokker-Medium";
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  width: 126px;
  border: none;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.marketPlace {
  font-family: "jokker-Bold";
  font-size: 18px;
}

.notificationDivider {
  border: 1px solid #707070;
  width: 97%;
  opacity: 0.5;
  margin-top: 20px;
}

.termAndExpertise {
  font-size: 18px;
  font-family: "jokker-Medium";
  color: #09143a;
}

.yearsOfPolicy {
  font-size: 18px;
  font-family: "jokker-Semibold";
  color: #09143a;
  opacity: 0.5;
}

.termAndExpertise {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2;
  margin-top: 14px;
}

.policyAndYear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2;
  font-size: 18px;
  color: #09143a;
  opacity: 0.5;
}

.providerAndAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2;
  font-size: 18px;
  color: #09143a;
  opacity: 0.5;
}

.policyNameAndPriceMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2;
  font-size: 18px;
  color: #09143a;
  opacity: 1;
}

.policyPriceDetail {
  font-family: "jokker-Semibold";
  font-size: 18px;
  color: #09143a;
  opacity: 0.5;
  white-space: nowrap;
}

.quoteCardDivider {
  border: 1px solid #09143a;
  margin-top: 16px;
  margin-left: -20px;
  margin-right: -20px;
}

.viewDocument {
  font-size: 18px;
  font-family: "jokker-Medium";
  color: #09143a;
  margin-top: 10px;
  text-align: center;
  /* cursor: pointer; */
}

.policyYearsInfo {
  font-family: "jokker-Semibold";
}

.providerAndPrice {
  font-family: "jokker-Medium";
}

.providerAndAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2;
  font-size: 18px;
  color: #09143a;
  opacity: 1;
  margin-top: 35px;
}

.policyNameDetail {
  font-size: 18px;
  font-family: "bunch-Bold";
  color: #09143a;
  opacity: 1;
}

.policyNameDetail.policy {
  font-size: 20px;
}

.gap-50 {
  gap: 50px;
}

.quotesCardsMain {
  margin-top: 40px;
  gap: 20px 12px;
  /* margin-left: -12px; */
}

.mainDashboard {
  margin-bottom: 50px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: var(--turquoise);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.quoteDrawerCard {
  border-radius: 12px;
  position: relative;
  width: 344px;
  height: 235px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  /* gap: 20px; */
  background-color: var(--alabaster) !important;
  box-shadow: 0px 6px 12px #00000008;
}

.card-active {
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 12px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: var(--alabaster) !important;
  box-shadow: 0px 6px 12px #00000008;
  justify-content: space-between;
  height: 137px;
}

.tag {
  /* position: absolute;
  top: 40px;
  right: 10px; */
  color: "#09143A";
  font-size: 10px;
  font-weight: 600;
}

.tag::first-letter {
  text-transform: uppercase;
}

/* .icon-bg-active-card {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: var(--white) !important;
} */

.card-active h1,
.card-active p {
  margin: 0;
  padding: 0;
}

.card-active h1 {
  font-size: 24px;
}

.card-active p {
  color: "#09143A";
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

.drafts-card .tag {
  color: var(--khamam-gray);
}

.other-status-card .tag {
  color: var(--khamam-gray);
}

.purchased-card .tag {
  color: var(--white);
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--violet);
}

.closed-card .tag {
  color: var(--white);
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--utility-red);
}

.active-card {
  background-color: var(--white);
}

.drafts-card {
  background-color: var(--white) !important;
}

.purchased-card {
  background-color: var(--peach);
}

.closed-card {
  background-color: var(--venial);
}

.other-status-card {
  background-color: var(--cream);
}

.active-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.active-card .tag {
  background-color: var(--utility-green);
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--white);
}

.drafts-card .tag {
  background-color: var(--header-bg);
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--white);
}

.drafts-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.purchased-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.closed-card .icon-bg-active-card {
  background-color: var(--chat-bg);
}

.other-status-card .icon-bg-active-card {
  background-color: var(--ponds-white);
}

.flex-wrap {
  flex-wrap: wrap;
}

.setting-img {
  margin: auto;
  display: block;
}
.setting-text {
  margin-top: 80px;
  text-align: center;
  font-size: 28px;
}

.setting-text span {
  font-weight: 400;
}

.agent-chat {
  height: calc(100vh - 250px);
  display: block;
}
.support-chat {
  height: calc(100vh - 250px);
  display: block;
}

.chat-header {
  background-color: var(--white);
  border-bottom: 1px solid var(--mystick-white);
  border-right: 1px solid var(--mystick-white);
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  width: calc(100vw - 500px);
  justify-content: space-between;
  /* max-width: 75%; */
}

.mr-30 {
  margin-right: 30px;
}

.user-logo {
  position: relative;
}

.online-status {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--yellow);
}

.online-status.idle,
.online-status.unknown {
  background-color: var(--charcoal-black);
}

.online-status.assigned {
  background-color: var(--lantern-green);
}

.online-status.waiting {
  background-color: var(--saffron);
}

.online-status-text {
  text-transform: capitalize;
  color: var(--charcoal-black);
}

.online-status-text.assigned {
  color: var(--lantern-green);
}

.online-status-text.waiting {
  color: var(--saffron);
}

.chat-icon-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.chat-icon-info h4 {
  padding: 0;
  margin: 0;
}

.chat-body {
  background-color: var(--dashboard-bg);
  height: 100%;
  position: relative;
  display: flex;
  /* flex-direction: column; */
  height: calc(100vh - 230px);
}
.chat-body-inner-main {
  width: -webkit-fill-available;
  width: -moz-available;
}
.chat-body-inner {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  /* height: 83%; */
  overflow-y: auto;
  padding: 0px 26px;
  height: calc(100vh - 230px);
}

.pr-10 {
  padding-right: 10px;
}

.chatBackIcon {
  cursor: pointer;
}
.userProfileName_div {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userProfileName {
  font-size: 24px;
  font-family: "jokker-Bold";
  line-height: 1 !important;
  /* margin-top: 0px !important; */
}

.chat-body-inner::before,
.chat-body-inner::after {
  content: "";
  transition: all ease 0.3s;
}

.chat-body-inner.shadowTop::before {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.chat-body-inner.shadowBottom::after {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
}
.no-more-messages {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px auto;
  text-align: center;
  display: block;
  width: 100%;
  color: var(--stone-gray);
}

.please-wait-for-messages {
  position: absolute;
  top: 45%;
  left: 0;
  margin: 10px auto;
  text-align: center;
  display: block;
  font-size: 30px;
  width: 100%;
  color: var(--stone-gray);
  opacity: 0;
  animation: fadeInOut 1.5s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.no-quotes-found-chat {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

/* .chat-body-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.chat-body-inner::-webkit-scrollbar {
  width: 10px;
}

.chat-body-inner::-webkit-scrollbar-thumb {
  background-color: var(--yellow);
} */

.sender {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}

.sender p {
  font-size: 14px;
  padding: 0;
  margin: 5px 0 0;
}

.sender-message {
  background-color: var(--olive-white);
  padding: 20px 40px;
  border-radius: 35px 35px 35px 0px;
}

.receiver {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  margin: 10px 0;
  justify-content: flex-end;
}

.receiver p {
  font-size: 14px;
  text-align: end;
  padding: 0;
  margin: 5px 0 0;
}

.receiver-message {
  background-color: var(--turquoise);
  padding: 20px 40px;
  border-radius: 35px 35px 0px 35px;
  color: var(--black);
  font-size: 18px;
  font-family: "jokker-regular";
}

.receiver-message img.image,
.sender-message img.image {
  width: 150px;
  height: auto;
}

.receiver-message img.document,
.sender-message img.document {
  width: 50px;
  height: 50px;
}

.main-content {
  padding: 20px;
}

.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

@media (max-width: 575px) {
  .sticky-sidebar {
    position: relative;
    top: 0;
  }
}

.chat-type-main {
  background: white;
  border-top: 1px solid var(--mystick-white);
  border-right: 1px solid var(--mystick-white);
}

.chat-type {
  height: 63px !important;
  display: inline-flex;
  width: 100%;
  gap: 20px;
  align-items: center;

  background: white;
  /*box-shadow: rgba(0, 0, 0, 0.1) -2px -8px 16px 0px;*/
  padding: 0px 30px;
  /* width: calc(99vw - 478px); */
  box-sizing: border-box;
}

.chat-type input,
.chat-type .attachement-preview {
  position: relative;
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: unset;
}
.chat-type .attachement-preview {
  display: flex;
  justify-content: flex-end;
}
.chat-type .attachement-preview .attachment {
  align-items: center;
  flex-direction: row-reverse;
  width: 50px;
  height: 50px;
  border: 3px solid var(--yellow);
}

.chat-type .attachement-preview .removeAttachment {
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
  flex: 1;
}
.chat-type .attachement-preview .removeAttachment:hover .removeAttachment-icon {
  top: -5px;
  width: 30px;
  height: 30px;
}
.chat-type .attachement-preview .removeAttachment .removeAttachment-icon {
  width: 20px;
  height: 20px;
  transition: all ease 0.2s;
}

.chat-type input:focus {
  outline: none;
}

.chat-type input::placeholder {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.chat-type input {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.send-warning {
  width: 100%;
  flex: 20 0 80%;
  font-size: 16px;
  font-weight: bold;
  color: var(--silver);
  cursor: not-allowed;
}

.imgArea {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgArea[data-disabled="true"] {
  cursor: not-allowed;
}

.imgArea img {
  width: 20px;
  height: 20px;
}

/* Sidebar */

.card-side-bar {
  display: inline-block;
  float: right;
  height: calc(100vh - 80px);
  width: 440px;
  /* padding: 1px 4px 5px 8px; */
  background-color: var(--black);
  margin-top: -87px;
  overflow-y: auto;
  /* position: absolute; */
  /* right: 22px; */
  border-left: 1px solid #4b5764;
  /* box-shadow: -6px 0px 40px #0000000d; */
}

.dividerForChat {
  margin: 20px -20px;
}

/* @media screen and (min-width: 1920px) and (min-height: 1080px) {
  .card-side-bar {
    margin-top: -192px; 
  }
} */

.card-side-bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #09143a;
} */

.insuranceTitle {
  font-family: "jokker-bold";
  font-size: 18px;
  /* margin-top: 30px; */
  /* margin-left: 150px; */
  color: #09143a;
}

.sidebarLeadDetailsName {
  font-family: "jokker-bold";
  font-size: 24px;
  color: var(--white);
  /* margin-left: 20px; */
}

.chatViewMore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--turquoise);
  /* margin-top: -25px; */
  gap: 10px;
}

.arrowIconViewMoreLead {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .arrowIconViewMoreLead svg {
  fill: var(--turquoise) !important;
} */

.sideBarMain {
  justify-content: space-between;
  /* margin-right: 20px; */
  /* margin-top: 20px; */
  margin-bottom: 20px;
}
.leadDetailsCardMainResponsive .ant-drawer-body {
  padding: 0px !important;
}
.leadDetailsName {
  font-family: "jokker-medium";
  /* margin-left: 84px; */
  /* color: gray; */
  color: var(--header-bg);
  /* opacity: 0.5; */
  cursor: pointer;
}

.sidebarLeadDetailsViewMore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 1px; */
  font-family: "jokker-medium";
  font-size: 18px;
  /* color: var(--turquoise); */
  cursor: pointer;
}

.sidebarLeadDetailsViewMoreLead {
  font-family: "jokker-medium";
  font-size: 18px;
  cursor: pointer;
  /* margin-top: 22px; */
}

.leadDetailsMainCard {
  padding: 20px;
}

/* .dividerForChat {
  margin-top: 10px;
} */
.secondaryBtn {
  margin-top: 12px;
}

.quoteResChat {
  font-family: "jokker-bold";
  font-size: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--white);
}

.arrowIconViewMore {
  margin-left: 5px;
}

.quoteResNotSubmitted {
  text-align: center;
  color: var(--black);
  text-align: center;
  font-family: "jokker-regular";
}

.submitQuoteResBtn {
  border: 1px solid black;
  width: 100%;
  border-radius: 8px;
  height: 4rem;
  font-family: "jokker-medium";
  font-size: 18px;
  background-color: var(--yellow);
  color: var(--black);
  cursor: pointer;
  margin-top: 12px;
  margin-left: auto;
  display: block;
  margin-right: auto;
}
.responsiveSubmitQuoteResBtn {
  display: none !important;
}
.submitQuoteResBtn:disabled {
  border: none;
  cursor: not-allowed !important;
  background-color: #838191 !important;
}

.quotesDetailsNotFound {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  /* margin-top: 20px; */
}

.noAnyQuotesResponse {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.sendPaymentBtn {
  border: 1px solid #838191;
  border-radius: 8px;
  height: 4rem;
  width: 100%;
  border-radius: 8px;
  font-family: "jokker-medium";
  font-size: 18px;
  background-color: var(--yellow);
  color: var(--black);
  cursor: pointer;
}

.sendPaymentBtn:disabled {
  cursor: not-allowed;
  background-color: var(--yellow);
  opacity: 0.6;
}

.markAsPurchasedBtn {
  border: 1px solid #838191;
  border-radius: 8px;
  height: 4rem;
  width: 100%;
  border-radius: 8px;
  font-family: "jokker-medium";
  font-size: 18px;
  background-color: var(--yellow);
  color: var(--black);
  margin-top: 15px;
  cursor: pointer;
}

.notSubmittedBox {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.markAsPurchasedBtn:disabled {
  cursor: not-allowed;
  background-color: var(--yellow);
  opacity: 0.6;
}

.uploadPolicyDocHeading {
  font-size: 24px;
  font-family: "jokker-Bold";
}

.uploadPolicyMain {
  margin-top: 85px;
}

.stateAndAffiliationDivider {
  border: 1px solid #4b5764;
  width: 100%;
}

.documentUploadedSection {
  background-color: #09143a;
  opacity: 0.1;
}

.dragPolicyDocAgentAgreement {
  border: 2px dashed #09143a;
  /* padding: 20px; */
  margin-bottom: 20px;
  border-radius: 24px;
  margin-top: 20px;
  height: 173px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "jokker-Medium";
  font-size: 18px;
  width: 100%;
}

.dragPolicyDoc {
  border: 2px dashed #09143a;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  margin-top: 20px;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "jokker-Medium";
  font-size: 18px;
}

.dragPolicyDocForSubmitQuote {
  border: 2px dashed #09143a;
  padding: 20px 25px 0px 8px;
  margin-bottom: 45px;
  border-radius: 24px;
  margin-top: 10px;
  width: 550px;
  height: 195px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "jokker-Medium";
  font-size: 18px;
  margin-left: 10px;
}

.personalDocumentUploadMain {
  justify-content: space-between;
}

.agentEnrollmentHeadingMain {
  justify-content: space-between;
}

.profileSection {
  justify-content: space-between;
}

.insuranceLicenseSaveIcon {
  background-color: #ffcd00;
  color: #09143a;
  font-size: 18px;
  font-family: "jokker-Medium";
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  width: 90px;
  border: none;
  padding: 10px;
  /* margin-left: 760px; */
  z-index: 9;
}

.custom-rectangle {
  background-color: rgba(75, 87, 100, 0.1);
  border-radius: 12px;
  height: 87px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  gap: 25px;
  margin: 40px auto;
  cursor: pointer;
}

.custom-rectangle_profile {
  background-color: rgba(75, 87, 100, 0.1);
  border-radius: 12px;
  justify-content: space-between;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 20px auto;
  cursor: pointer;
}

.updateProgileSaveBtn {
  border-radius: 8px;
  background-color: #ffcd00;
  color: #09143a;
  font-size: 16px;
  font-family: "jokker-Medium";
  border: none;
  /* margin-right: -28px; */
  width: 100px;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
}

.myProfileDocsHeading {
  justify-content: space-between;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.quoteRespHeading {
  color: var(--white);
  font-size: 24px;
  margin-top: 25px;
  font-family: "Jokker-Bold";
  /* margin-left: 40px; */
}

.uploadDocumentsQuotes {
  font-size: 24px;
  font-family: "jokker-Bold";
}

.submitQuote {
  column-gap: 15px;
  box-shadow: 0px 5px 15px #b3b3b340 !important;
  border-radius: 8px !important;
  opacity: 1;
  color: #000 !important;
  background: #ffb600 !important;
  /* font-family: "NB" !important; */
  height: 45px !important;
  min-width: 140px !important;
  font-family: "jokker-Medium" !important;
}

.policyDocSubmitBtn {
  background-color: #ffcd00;
  font-size: 18px;
  font-family: "jokker-Medium";
  border: none;
  border-radius: 8px;
  width: 125px;
  height: 40px;
  margin-left: 540px;
  cursor: pointer;
}

.errorMessage {
  color: red;
}

.chatHeader {
  font-size: 24px;
  font-family: "jokker-Bold";
}

.chatScreenMainBtn {
  margin-top: 40px;
}

.termHeading {
  font-family: "jokker-medium";
  font-size: 18px;
}

.expireHeading {
  text-align: left;
  position: absolute;
  top: 1px;
  right: 0px;
  letter-spacing: 0px;
  color: #09143a;
  opacity: 1;
  padding-left: 202px;
  font-family: "jokker-Medium";
  opacity: 1;
}

.primiumAmtquote {
  text-align: left;
  position: absolute;
  top: 1px;
  right: 0px;
  letter-spacing: 0px;
  color: #09143a;
  opacity: 1;
  padding-left: 166px;
  font-family: "jokker-Medium";
  opacity: 1;
}

.geicoQuote {
  color: #09143a;
  opacity: 1;
  font-size: 28px;
  text-align: left;
  font-family: "bunch-bold";
  opacity: 1;
}

.quoteYear {
  color: #84899c;
  /* text-align: left; */
  opacity: 0.5;
  font-family: "jokker-Semibold";
  font-size: 18px;
}

.quoteDate {
  color: #84899c;
  font-size: 18px;
  font-family: "jokker-Semibold";
  opacity: 0.5;
  margin-left: 221px;
  /* text-align: left; */
}

.primumAmount {
  margin-left: 206px;
  color: #84899c;
  font-family: "jokker-Semibold";
  font-size: 18px;
}

.primumAmountDrawer {
  margin-left: 206px;
  color: #84899c;
  font-family: "jokker-Semibold";
  font-size: 18px;
}

.paymentInput {
  border-radius: 4px;
  border: 1px solid #707070;
  opacity: 1;
  width: 100%;
}

@media (min-width: 576px) {
  .responsive-box {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .responsive-box {
    width: 75%;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .responsive-box {
    width: 50%;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .responsive-box {
    width: 40%;
    margin: auto;
  }
}

/* Box styles */
.responsive-box {
  background-color: #fff;
  border: 2px solid #00000008;
  border-radius: 12px;
  /* padding: 6px 26px 2px 12px; */
  padding: 20px;
  height: max-content;
  width: auto;
  flex-wrap: wrap;
}

.send-btn {
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 100%; */
  cursor: pointer;
}
.send-btn img{
  width: 100%;
  height: 100%;
}
.send-btn.disabled {
  /* background-color: grey; */
  cursor: not-allowed;
}

/* .send-btn.active:hover {
  background: var(--selective-yellow);
} */

.bottom-icon {
  position: absolute;
  bottom: 75px;
  right: 50%;
  border-radius: 30px;
  z-index: 10;
  cursor: pointer;
}

.d-block {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.home-image {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: var(--off-white);
}

.preview-header-left {
  display: flex;
  gap: 40px;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  justify-content: space-between;
}

.preview-header {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.home-text h1,
.home-text p {
  margin: 0;
  padding: 0;
}

.home-text p {
  color: var(--black-current);
  font-size: 18px;
}

.home-text h1 {
  color: var(--header-bg);
  font-size: 28px;
}

.home-text h1 span {
  color: var(--black);
}

.preview-tab {
  width: 250px;
  height: 44px;
  /* background-color: var(--cape-white); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  opacity: 30%;
  border-bottom: 2px solid var(--header-bg);
}

.quotes-tab {
  width: 250px;
  height: 44px;
  /* background-color: var(--cape-white); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.quotesTabActivated {
  opacity: 100%;
}

.edit-button {
  padding: 10px 40px;
  border-radius: 5px;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.common-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 40px;
  margin-top: 40px;
  background-color: var(--white);
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.insurance-heading {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-bottom: 40px;
}

.insurance-heading h1 {
  font-size: 24px;
}

.insurance-heading::before {
  content: " ";
  height: 3px;
  width: 70px;
  background-color: var(--yellow);
  position: absolute;
  bottom: -2px;
  left: 0;
}

.insurance-subheading {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  font-family: "jokker-bold";
  text-align: left;
}


.leadInfoMain {
  background-color: var(--black);
  border-radius: 30px;
  flex-wrap: wrap;
  padding: 40px;
}

.agentEnrollmentMain {
  background-color: var(--white);
  border-radius: 30px;
  flex-wrap: wrap;
  height: 40vh;
}

.agentEnrollMentHeading {
  /* margin-left: 12px; */
  color: #09143a;
  font-size: 24px;
  font-family: "jokker-bold";
  text-align: left;
  letter-spacing: 0px;
  flex: none;
  /* margin-top: 2px; */
}

.uploadedPdf {
  color: #09143a;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.agentEnrollMentProfilePicture {
  margin-right: 310px;
  font-size: 24px;
  font-family: "jokker-bold";
  text-align: left;
  letter-spacing: 0px;
}

.agentEnrollMentHeadingScanDocs {
  font-size: 24px;
  font-family: "jokker-bold";
  text-align: left;
  letter-spacing: 0px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.insuranceLeadingDivider {
  border: 1px solid #4b5764;
  margin-top: 30px;
  opacity: 0.54;
}

.licenseHeading {
  margin-top: 20px;
  font-family: "jokker-bold";
  color: #09143a;
  font-size: 24px;
}

.licenseInfoMain {
  justify-content: space-between;
}

.companyDetailMain {
  justify-content: space-between;
}

.addCompanyDetails {
  background-color: #ffcd00;
  color: #09143a;
  font-family: "jokker-Medium";
  font-size: 18px;
  border-radius: 8px;
  width: 90px;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 22px;
}
.coverage_vehicle_floating {
  /* text-transform: capitalize; */
  font-size: 16px;
  margin: 0px 0px 20px 0px;
  /* font-family: "jokker-bold"; */
  color: var(--header-bg);
  position: absolute;
  top: -9px;
  background: var(--white);
  left: 20px;
  padding: 0px 10px;
}
.addCompanyDetailsInfo {
  background-color: #ffcd00;
  color: #09143a;
  font-family: "jokker-Medium";
  font-size: 18px;
  border-radius: 8px;
  width: 90px;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 22px;
  /* margin-right: 100px; */
}

.companyAffilication {
  font-size: 24px;
  color: #09143a;
  font-family: "jokker-Bold";
  margin-top: 20px;
  /* margin-left: 10px; */
}

.companiesInfoMainPage {
  justify-content: space-between;
}

.footerBtn {
  display: flex;
  justify-content: space-between;
}

.footerBtnAgent {
  display: flex;
  justify-content: end;
}
.agent-footer{
  text-align:center;
  background-color: #FFF;
  margin-top: 10px;
  position: fixed;
  width: calc(100% - 100px);
  bottom: 0;
  z-index: 99;
  padding: 24px 50px;
}
.personalInfo {
  margin-left: 12px;
  color: var(--white);
  font-size: 24px;
  font-family: "jokker-bold";
  text-align: left;
  letter-spacing: 0px;
}

.divider {
  margin-top: 28px;
  color: var(--white);
}

.chack-insurance-label {
  font-size: 14px;
  max-width: 300px;
  color: var(--dove-gray);
  white-space: nowrap;
  margin-left: 30px;
}

.chack-insurance-text {
  font-size: 14px;
  color: var(--black);
  font-weight: 700;
  white-space: nowrap;
  margin-left: 28px;
}

.chack-insurance-info-main {
  display: flex;
  gap: 10px;
  margin-left: 10px;
  flex-wrap: wrap;
  row-gap: 5px;
  margin-bottom: 10px;
}

.chack-insurance-info-main:last-child {
  margin-bottom: unset;
}
.insurance-label {
  white-space: wrap;
  color: var(--black-current);
  font-size: 16px;
}


.insurance-text,
.insurance-text span {
  /* max-width: 256px; */
  /* min-width: 250px; */
  width: auto;
  white-space: wrap;
  color: var(--black-current);
  font-size: 16px;
  font-family: "jokker-semibold";
  display: flex;
  /* align-self: flex-end; */
  flex-direction: column;
}
.insurance-text {
  padding: 16.5px 14px;
  border-radius: 4px;
  border: 1px solid #e6e7eb;
  border-color: rgba(0, 0, 0, 0.23);
}
.insurance-text.email {
  word-wrap: break-word;
  word-break: break-word;
}

.border {
  border-radius: 8px;
  background-color: var(--white);
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.next-button {
  margin: unset;
}

.mb-44 {
  margin-bottom: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

.insurance {
  padding: 80px 0;
  margin-top: 80px;
}
.insuranceInfo h4 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.insuranceInfo p {
  margin-bottom: 0;
  padding: 0;
  margin-top: 0;
  font-size: 16px;
  line-height: 25px;
}
.insuranceInfo h6 {
  font-size: 18px;
  font-weight: 500;
  text-align: end;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  padding: 0;
}
.insuranceInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
}

.main-step {
  display: flex;
  gap: 20px;
  position: relative;
}

.applicant-stap {
  display: flex;
  gap: 20px;
  z-index: 2;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
}

.stap-count {
  height: 24px;
  width: 24px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.staper-active .stap-count {
  background-color: var(--yellow);
  color: var(--header-bg);
}

.inactive .stap-count {
  background-color: var(--yellow);
  color: var(--header-bg);
}

.complete .stap-count {
  background-color: var(--utility-green);
  color: var(--white);
}

.complete p {
  color: var(--utility-green);
}

.applicant-stap p {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  max-width: 150px;
}

.inactive p {
  color: var(--header-bg);
}

.inactive {
  opacity: 50%;
}

.main-step:before {
  /* position: absolute;
  height: 3px;
  width: 100%;
  top: 0;
  left: 0;
  top: 50%;
  content: "";
  z-index: 1;
  background-color: var(--alto); */
}

.applicant-info-main {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--header-link);
  padding: 41px 0px;
  overflow-y: auto;
}

.insuranceMain img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.customer-information-out-line {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--white);
}

.customer-information {
  padding-bottom: 40px;
}

.full-rounded {
  border-radius: 100px;
}

.customer-information-heading {
  position: relative;
  margin: 0;
  padding: 0;
  color: var(--header-bg);
  font-family: "jokker-bold";
  font-size: 24px;
  opacity: 1;
}

.customer-information-heading h1 {
  margin: 0;
  padding: 0;
}

.customer-information-input {
  width: 49%;
  position: relative;
}

.addCompanyUploadDocument {
  width: 98%;
}

.start-button {
  margin-left: auto;
  margin-right: unset;
  font-size: 18px;
  /* padding: 20px 40px; */
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.previous-button {
  margin-right: auto;
  margin-left: unset;
  font-size: 18px;
  padding: 20px 40px;
  height: 64px;
  display: flex;
  gap: 10px;
  color: var(--white);
  transition: 0.5s;
}

.start-button span {
  min-width: 75px;
}

.previous-button span {
  min-width: 75px;
}

.next-img {
  display: none;
}

.start-button:hover .next-img {
  display: block;
}

.previous-button:hover .next-img {
  display: block;
  transform: rotate(180deg);
}
.loginPopup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  backdrop-filter: blur(5px);
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: center;
}

.fach-data:hover {
  border: 2px solid var(--yellow);
}

.fach-data-design {
  width: 172px;
  height: 172px;
  background-color: var(--dairy-cream);
  border-radius: 100%;
  position: absolute;
  top: -30%;
  left: -30%;
  transition: 0.9s;
}
.fach-data:hover .fach-data-design {
  left: 60% !important;
  top: -50% !important;
}
.fach-data h6,
.fach-data img {
  z-index: 99;
  position: relative;
}

.fach-data h6 {
  font-size: 18px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  flex-direction: column;
  font-weight: 600;
}

.fach-data {
  width: 240px;
  border: 2px solid transparent;
  background-color: var(--white);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  text-align: center;
  padding: 20px;
}
.quat-heading {
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
.quat-heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 40px;
  background-color: var(--orange);
}

.quat-heading-icon {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation-icon {
  height: 30px;
  background-color: var(--pink-lady);
  border-radius: 10px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset !important;
}

.gap-50 {
  gap: 50;
}

.quote-card {
  max-width: 314px;
  border: 1px solid var(--cream);
  background-color: var(--alabaster);
  border-radius: 10px;
  padding: 20px;
  margin-right: 40px;
  position: relative;
}

.quote-card-purchased::before {
  position: absolute;
  right: -68px;
  content: "Purchased";
  height: 35px;
  width: 100px;
  background-color: var(--yellow);
  color: var(--white);
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transform: rotate(90deg);
}

.quote-card-accepted::before {
  position: absolute;
  right: -68px;
  content: "Accepted";
  height: 35px;
  width: 100px;
  background-color: var(--green);
  color: var(--white);
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transform: rotate(90deg);
}

.mt-30 {
  margin-top: 30px;
}

.quote-card h4 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 700;
}

.quote-card-is-accepted {
  background-color: var(--green);
  border-radius: 4px;
  color: var(--white);
  font-size: 12px;
  padding: 7px 9px;
  margin-right: 10px;
}

.quote-card-prise {
  background-color: var(--utility-green);
  border-radius: 4px;
  color: var(--white);
  font-size: 16px;
  padding: 11px 13px;
}

.quote-name {
  font-size: 22px;
  font-family: "jokker-bold";
}

.quote-card-details {
  margin-top: 20px;
  border-top: 1px solid var(--pearl);
  border-bottom: 1px solid var(--pearl);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-card-label {
  font-size: 14px;
  font-weight: 800;
  min-width: 100px;
  font-family: "jokker-medium";
  font-size: 18px;
}

.quote-card-label-detail {
  font-size: 13px;
  color: var(--dusty-gray);
}

.quote-card-document {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.quote-accept-button {
  padding: 8px 16px;
  border-radius: 4px;
  color: var(--header-bg);
  font-size: 13px;
  cursor: pointer;
  transition: 0.5s;
  border: unset;
  background-color: var(--yellow);
}

.quote-accept-button[disabled] {
  cursor: not-allowed;
}

.quote-accept-button:hover {
  background-color: var(--header-bg);
  color: var(--white);
}

.quote-card-document-icon {
  height: 31px;
  width: 31px;
  border: 1px solid var(--light-black);
  border-radius: 4px;
}

.profile-head {
  background-color: var(--white);
  height: 120px;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  color: var(--header-bg);
  display: flex;
  align-items: center;
}

.profile-input-section {
  border: 1px solid var(--mercury-white);
  border-radius: 24px;
  padding: 90px 32px 40px 32px;
  background-color: var(--white);
  margin-bottom: 80px;
  position: relative;
  margin-top: 30px;
}

.slick-arrow {
  display: none !important;
}

.profile-thumb-main {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  display: inline-table;
  margin-right: auto;
}

.profile-input {
  width: 100%;
}

.flex_box_profile {
  display: flex;
  justify-content: space-between;
  gap: 45px;
  align-items: center;
  width: -moz-available;
  width: -webkit-fill-available;
}

.support-chat .chat-board {
  height: 100%;
  width: 100%;
  display: block;
}
.agent-chat .chat-board {
  height: 100%;
  width: 75%;
  display: inline-block;
}

.w-100 {
  width: 100% !important;
}

.mt-0 {
  margin-top: 0;
}

.inner-form {
  margin-top: 40px;
}

.prifix {
  position: relative;
  align-items: center;
}

.prifix .tooltipText {
  position: absolute;
  visibility: hidden;
  width: 120px;
  background-color: var(--selective-light-yellow);
  color: var(--black);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1;
}

.prifix:hover .tooltipText {
  visibility: visible;
}

.prifix .tooltip img {
  margin-left: 5px;
  margin-top: 4px;
}

.inner-form-heading {
  padding-bottom: 30px;
  font-family: "jokker-medium";
  font-size: 18px;
  margin: 0;
}

.slider-label {
  color: var(--light-black);
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.check-info {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.pb-0 {
  padding-bottom: 0px;
}

.ml-30 {
  margin-left: 30px;
}

.flood-start-button {
  width: 119px !important;
  height: 44px !important;
  margin: unset !important;
  border-radius: 4px !important;
  min-width: unset !important;
}

.roofOption p {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
}
.roofOption {
  cursor: pointer;
  text-align: center;
  padding: 15px 15px 10px 15px;
  border-radius: 20px;
  transition: all ease 0.1s;
  margin-right: 20px;
}

.roofOption:hover .roofOptionImg,
.roofOption.active .roofOptionImg {
  filter: grayscale(0);
  border: 1px solid black;
}

.roofOption.active p {
  color: black;
}
.roofOption p {
  color: grey;
}
.companyNameEnrollment{
  margin: 0px !important;
}
.roofOptionImg {
  align-content: center;
  border: 2px solid #b1b0b0;
  border-radius: 100px;
  display: grid;
  height: 80px;
  justify-content: center;
  width: 80px;
  margin: 0 auto;
}
.roofOptionImg + p {
  width: 120px;
  text-align: center;
  margin-bottom: 0px;
}
.radio-icon-preview {
  display: flex;
  flex-direction: column;
  width: max-content;
  text-align: center;
}
.grayscale {
  filter: grayscale(1);
}

.roofLabel {
  color: var(--light-gray);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.mainNoRecordFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 25px;
  font-size: 22px;
  font-weight: 800;
}
.agentNotFound {
  text-align: center;
  font-weight: 800;
}

.outline-primary-btn {
  border: 2px solid var(--selective-light-yellow);
  color: var(--selective-light-yellow);
  margin-left: auto;
  margin-right: unset;
  font-size: 18px;
  height: 42px;
  padding: 8px 25px;
  width: 136px;
  display: flex;
  gap: 10px;
  transition: 0.5s;
  background-color: var(--white);
}

.outline-primary-btn:hover {
  color: var(--white);
  background-color: var(--selective-light-yellow);
}

.outline-primary-btn span {
  min-width: 75px;
}

.accordion {
  max-width: 100%;
}

.accordion-item {
  list-style: none;
}

.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--light-silver);
  padding: 1em;
}

.accordion-toggle:hover {
  background-color: var(--alto);
}

.accordion-toggle h3 {
  margin: 0;
}

.accordion-content {
  background-color: var(--gallery);
  padding: 1em;
}

.font-15 {
  font-size: 15px;
}

/* .document-footer img {
  width: auto;
  height: 50px;
} */
.insurance-main {
  display: flex;
  flex-direction: row;
  gap: 34px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.insurance-popup {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}

.insurance-card {
  height: 206px;
  width: 206px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  background: var(--white) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
  opacity: 1;
  cursor: pointer;
}

.insurance-card:hover {
  border: 1px solid var(--selective-light-yellow);
}

.add-more-btn {
  min-width: 80px;
  height: 40px !important;
  font-size: 13px !important;
}

.remove-icon {
  position: absolute;
  top: -10px;
  right: -8px;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  padding: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.preview-edit {
  position: absolute;
  top: -10px;
  right: -8px;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  padding: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.w-full {
  width: 100%;
}

.multi-entry-field .customer-information-input {
  width: 48.9% !important;
}

.main-dialog {
  min-height: 100px;
  min-width: 300px;
  padding: 10px;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  padding: 10px;
  border-bottom: 1px solid var(--off-silver);
}

.dialog-btn {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
}

.dialog-body {
  padding: 10px 10px;
}

.customer-radio-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file-upload-box {
  border: 2px dashed var(--header-bg);
  border-radius: 24px;
  min-height: 308px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 53px;
  font-weight: 400;
  font-size: 15px;
}

.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload-text {
  text-align: center;
/* color: var(--white) !important; */
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-medium";
}

.chatBodyDrawer .FilesDragAndDrop__area {
  border: 2px dashed var(--white) !important;
}
.chatBodyDrawer .FilesDragAndDrop__area svg {
  fill: var(--white) !important;
}

.chatBodyDrawer .file-upload-text,.chatBodyDrawer .file-link, .uploadPolicyDocHeading {
  color: var(--white) !important;
}
.chatBodyDrawer .fileDragMain {
  margin : 20px 0px;
}
.leadsDetailsAccordion .fileDragMain{
  margin-bottom: 24px;
}
.file-link {
  text-decoration: underline;
  /* color: var(--white) !important; */
}

.insurance-header {
  position: sticky;
  z-index: 99;
  top: 83px;
  display: flex;
  align-items: center;
  height: 80px;
  background-color: var(--white);
}

.insurance-header-flex {
  display: flex;
  justify-content: space-between;
}

.breadcrumbs-contain {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.breadcrumbs-step {
  color: var(--header-bg);
  text-align: left;
  font-family: "jokker-medium";
  font-size: 18px;
  opacity: 0.5;
  cursor: pointer;
}

.saveAsDraftBtn {
  width: 177px;
  height: 53px;
  border-radius: 8px;
}

.breadcrumbs-step:nth-last-child(-n + 2) {
  opacity: 1;
  cursor: context-menu;
}

.gap-40 {
  gap: 40px;
}

.card-save-btn {
  width: 105px;
  height: 53px;
  border-radius: 8px;
}

.vaultPinContain {
  background-color: var(--white);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  padding: 60px 0px;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}

.vaultPinTitle {
  color: var(--header-bg);
  font-size: 24px;
  font-family: "jokker-bold";
  padding: 20px 0px 10px 0px;
  margin: 0;
}

.vaultPinBtn {
  width: 375px;
  height: 56px;
  border-radius: 8px;
}

.vaultPinDescription {
  color: var(--header-bg);
  text-align: center;
  font-size: "jokker-regular";
  margin: 0;
}

.otpContainer {
  display: flex;
  flex-direction: row;
  gap: 17px;
}

.pin-section {
  padding: 30px 0px 40px 0px;
}

.otpInput {
  width: 75px;
  height: 45px;
  border: none;
  font-family: "jokker-bold";
  border-bottom: 2px solid var(--header-bg) !important;
  font-size: 30px;
  text-align: center;
  color: var(--primary);
  padding: 0;
}

.otpInput:hover,
.otpInput:focus,
.otpInput:focus-visible {
  outline: none;
  border: 1px solid var(--primary);
}

.overview_insurance_card_policy_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview_insurance_card_policy_status {
  border-radius: 8px;
  color: var(--white);
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  font-family: "jokker-medium";
  text-transform: capitalize;
  background-color: var(--utility-green);
}

.policy_status_expire {
  background-color: var(--utility-red);
}

.pt-40 {
  padding-top: 40px;
}
.overview_card:hover .overview_card_number h1,.overview_card:hover .overview_card_heading h2,.overview_card:hover .overview_card_icon{
  color: var(--white) !important;
}

.overview_card-text {
  color: var(--turquoise);
}

.overview_card_number h1 {
  margin: 0;
  padding: 0;
  color: var(--yellow);
  font-size: 64px;
}

.overview_card {
  cursor: pointer;
  padding: 30px;
  background-color: var(--header-bg);
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.overview_card_icon {
  color: var(--turquoise);
}

.profile-thumb-main .secondUserIcon {
  height: 100px;
  width: 100px;
}

.mb-30 {
  margin-bottom: 30px;
}

.select-box {
  display: flex !important;
  margin-bottom: 8px !important;
  align-items: end !important;
  position: relative;
}

.input-box {
  position: relative;
}

.select-box p {
  position: absolute;
  bottom: -19px;
  left: 7px;
}

.input-box p {
  position: absolute;
  bottom: -10px;
  left: 7px;
}

.hero_section {
  /* background-color: var(--header-bg);
  background-image: url(../assets/images/banner-background.webp);
  background-position: 50% 6%;
  background-repeat: no-repeat; */
}

.hero_main {
  /* padding-top: 200px; */
}

/* .hero_text h1 {
  font-size: 50px;
  color: var(--white);
  margin-bottom: 30px;
} */

/* .hero_text p {
  font-size: 20px;
  color: var(--yellow);
  margin: 0px;
  font-family: "jokker-light";
  font-weight: 400;
  line-height: 28px;
} */

/* .hero_text {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
} */
/* 
.about_image {
  display: flex;
  justify-content: end;
  padding-right: 15%;
} */

/* .sub_heading {
  color: var(--header-bg);
  font-size: 18px;
  margin: 0;
} */

/* .main_heading {
  color: var(--header-bg);
  font-size: 50px;
  margin-top: 12px;
  margin-bottom: 30px;
} */

/* .aboutText p {
  font-size: 20px;
  line-height: 28px;
  max-width: 505px;
} */

/* .about_card {
  padding: 30px;
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 24px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 160px;
  max-width: 400px;
} */

/* .about_card h3 {
  margin: 0;
  font-size: 24px;
  font-family: "jokker-bold";
  color: var(--header-bg);
} */

/* .about_card p {
  margin: 0;
  font-size: 16px;
  color: var(--header-bg);
  max-width: 295px;
  line-height: 24px;
} */

/* .values_card {
  padding-top: 32px;
  padding-bottom: 0px;
  margin-top: 50px;
} */

/* .values_card h2 {
  font-family: "jokker-bold";
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--header-bg);
  max-width: 250px;
} */

/* .values_card h4 {
  margin-top: 20px;
} */

/* .values_card p {
  font-family: "jokker-regular";
  font-size: 16px;
  margin: 0px;
  color: var(--header-bg);
  line-height: 24px;
  max-width: 320px;
} */

/* .service_section {
  background-color: var(--header-bg);
  padding: 70px 0;
} */

/* .offer_card {
  padding: 40px;
  border-radius: 24px;
  background-color: var(--header-bg);
  display: flex;
  flex-direction: column;
  gap: 40px;
  border: 1px solid var(--white);
  color: var(--white);
  cursor: pointer;
  max-width: 400px;
  height: 480px;
  position: relative;
} */

/* .offer_card:hover {
  background-color: var(--white);
  color: var(--black);
}

.offer_card:hover.offer_card h3 {
  color: var(--black);
}

.offer_card:hover.offer_card p {
  color: var(--black);
}

.offer_card h3 {
  color: var(--white);
  font-size: 32px;
  line-height: 38px;
  font-family: "jokker-bold";
  margin: 0;
} */

/* .offer_card p {
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
  font-family: "jokker-light";
  margin: 0;
  min-height: 160px;
  max-width: 320px;
  line-height: 24px;
} */

/* .offer_card svg {
  font-size: 50px;
  position: absolute;
  bottom: 40px;
  left: 40px;
} */

.Insurance_Marketplace {
  padding: 100px 0;
}

.Insurance_Marketplace_card {
  height: 254px;
  border-radius: 20px;
  padding: 0px;
  transition: 0.5s;
  margin-top: 0 !important;
  width: 295px;
  /* margin-bottom: 20px; */
}

.Insurance_Marketplace_card img {
  margin-top: 33px;
  margin-left: 33px;
}

.Insurance_Marketplace_card h4 {
  opacity: 0;
  transition: 0.5s;
  margin: 0 33px;
  margin-bottom: 33px !important;
}

.Insurance_Marketplace_card h2,
.Insurance_Marketplace_card p {
  margin: 0 33px;
}

/* .Insurance_Marketplace_card:hover.Insurance_Marketplace_card h4 {
  opacity: 1 !important;
} */

/* .Insurance_Marketplace_card:hover {
  background-color: var(--white);
  box-shadow: 0px 6px 12px #00000008;
} */

.join_section {
  background-color: var(--white);
  padding: 50px 0;
}

.partner {
  padding: 100px 0;
}

.team_desc .p_tag {
  max-width: 295px;
}

.team_sub_desc .p_tag {
  max-width: 381px;
}

.team_sub_desc h2 {
  max-width: 265px;
}

.p_tag {
  font-family: "jokker-regular";
  font-size: 16px;
  margin: 0px;
  color: var(--header-bg);
  line-height: 24px;
}

.partner_card {
  background-color: var(--white);
  border-radius: 20px;
  min-height: 492px;
  transition: 0.5s;
}

.partner_card:hover {
  transform: translateY(-10px);
}

/* .partner_card_image {
  padding: 47px 30px;
  border-bottom: 1px solid #ffefe8;
  height: 100px;
} */

.partner_card_image img {
  height: 100%;
  width: auto;
  max-width: 250px;
  object-fit: contain;
}

.card_title {
  font-family: "jokker-bold";
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--header-bg);
}

.mt-50 {
  margin-top: 30px;
}

.contact_section {
  background-color: var(--white);
  display: flex;
  position: relative;
  z-index: 1;
}

.contact_section_main {
  position: relative;
  background-color: var(--white);
}

.contact_section_main::before {
  height: 100%;
  width: 50%;
  background-color: var(--header-bg);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.promis_section {
  background-color: var(--header-bg);
  padding: 100px 0px 0px 0px;
  width: 50%;
}

.form_section {
  padding: 67px 0px 67px 67px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: var(--white);
}

.promis_section p {
  max-width: 400px;
}

.form_fild p {
  color: var(--header-bg);
  width: 100%;
  font-size: 16px;
  font-family: "jokker-regular";
  margin: 0;
}

.form_fild input {
  color: var(--header-bg);
  width: 100%;
  font-size: 16px;
  font-family: "jokker-regular";
  margin: 0;
  height: 40px;
  width: 100%;
  border: unset;
  border-bottom: 1px solid var(--header-bg);
}

.form_fild input:focus {
  outline: unset;
}

.form_fild textarea {
  color: var(--header-bg);
  width: 100%;
  font-size: 16px;
  font-family: "jokker-regular";
  margin: 0;
  width: 100%;
  border: unset;
  border-bottom: 1px solid var(--header-bg);
}

.form_fild textarea:focus {
  outline: unset;
}

.submit_button {
  padding: 17px 50px;
  background-color: var(--yellow);
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-medium";
  border: unset;
  width: fit-content;
  border-radius: 8px;
}

.footer_section {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  background-color: var(--turquoise);
}

.footer_details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.footer_details p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: "jokker-medium";
}

.checkbox-contain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-contain span {
  padding: 0 !important;
}

.checkbox-label {
  font-size: 18px;
  color: var(--granite-gray);
  font-family: "jokker-medium";
}

.verification-status {
  background: var(--violet);
  border-radius: 5px;
  padding: 8px 15px;
  color: var(--white);
  font-family: "jokker-regular";
}

.insurance-marketplace-card {
  background-color: var(--header-bg);
  height: 352px;
  border-radius: 24px;
}

.option_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 0.5px solid var(--white);
}

.option_card_body {
  color: var(--white);
  font-size: 16px;
  text-align: left;
  font-family: "jokker-regular";
  line-height: 24px;
}

.loan_reference_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
}

.address_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
}

.insurance_login_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
}

.upload_existing_card {
  border-radius: 24px;
  background-color: var(--white);
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container_width {
  /* padding: 0px 50px; */
  width: -webkit-fill-available;
  width: -moz-available;
  /* max-width: 75%; */
  margin: auto;
  position: relative;
  padding: 0 16px;
}

/* .hero_image_main {
  margin-bottom: -3px;
} */

/* .insurance_marketplace_box {
  position: absolute;
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  background-color: var(--white);
  left: 20px;
  bottom: 0;
  z-index: 3;
  border-radius: 24px;
  padding: 40px;
  display: flex;
} */

/* .insurance_marketplace_box p {
  font-size: 16px;
  line-height: 24px;
  color: var(--header-bg);
  font-family: "jokker-regular";
  margin-top: 0px;
} */

/* .insurance_marketplace_box .devider {
  height: auto;
  width: 2px;
  background-color: var(--header-bg);
  opacity: 0.2;
  margin: 25px 105px 25px 75px;
} */

/* .insurance_marketplace_text {
  margin: 25px 0;
} */

.insurance_marketplace {
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 201px;
}

.insurance_marketplace h3 {
  color: var(--header-bg);
  font-size: 32px;
  line-height: 38px;
  font-family: "jokker-bold";
  margin: 0;
}

.offer_card:nth-child(3) h3 {
  max-width: 100px;
}

.chat-responsive-side-bar {
  display: none;
  margin: unset;
  transform: unset;
  margin-top: unset;
}

.insurance_marketplace svg {
  font-size: 50px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  transform: rotate(180deg);
}

.offer_card:hover .light_img {
  display: none;
}

.offer_card:hover .dark_img {
  display: block;
}

.dark_img {
  display: none;
}

.common-shadow {
  padding: 20px 20px;
}

.common-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px;
  margin-top: 40px;
  background-color: var(--white);
}

.agentSubmit {
  color: rgb(9, 20, 58);
  background-color: rgb(255, 205, 0);
  border-radius: 12px;
  font-family: jokker-Medium;
  font-size: 18px;
  width: 100px;
  border: none;
  height: 53px;
  cursor: pointer;
}

.MuiStepIcon-root {
  color: #30ebf2 !important;
  width: 2rem !important;
  height: 2rem !important;
}

.Mui-completed {
  color: #54c193 !important;
}

.Mui-disabled .MuiStepIcon-root {
  color: white !important;
}

.Mui-completed .MuiStepIcon-text {
  fill: white !important;
}

.MuiStepIcon-text {
  fill: black !important;
}

.Mui-disabled.MuiStepLabel-alternativeLabel {
  color: white !important;
}

.Mui-completed .MuiStepLabel-alternativeLabel {
  color: #30ebf2 !important;
}

.MuiStepLabel-alternativeLabel {
  font-size: 20px !important;
  color: #30ebf2 !important;
}

.MuiSelect-multiple {
  max-width: 350px !important;
}

.chat-body-inner-content {
  height: -webkit-fill-available;
  height: -moz-available;
  overflow: auto;
}
.conditional-multi-entry-card.cardsection {
  border: 2px solid #e6e7eb;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}
.multi-entry-question{
  border-bottom: 2px solid #e6e7eb;
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: unset;
}

.header_container {
  max-width: 95% !important;
}
@media only screen and (max-width: 1700px) {
  .chat-body-inner-content {
    width: unset;
    margin-left: unset;
  }

  .mr-30 {
    margin-right: unset;
  }
  .coverages_item {
    padding: 15px 0px;
  }
}

@media only screen and (max-width: 1600px) {
  /* .insurance-text,
  .insurance-text span {
    max-width: 215px;
  } */
}

/* @media only screen and (max-width: 1500px) {
  .insurance-text,
  .insurance-text span {
    max-width: 200px;
  }
} */

@media only screen and (max-width: 1440px) {
  /* .container_width {
    padding: 0px 50px;
    max-width: 1240px;
  } */

  /* .agent_container_width {
    padding: 0px 50px;
    max-width: 1240px;
  } */
}

@media only screen and (max-width: 1440px) {
  .popupBg .illuuuu {
    width: 210px;
  }
  .popupBg .form_section input,
  .popupBg .form_section .MuiOutlinedInput-input {
    padding: 10px !important;
  }
  .form_section label {
    font-size: 14px !important;
    top: -4px !important;
  }
  .model-contain-main-responsive-upload {
    max-height: 80vh !important;
  }
  .how_work_section,
  .aboutSection,
  .partner {
    padding: 50px 0 !important;
  }

  /* .quote-list-model .MuiDrawer-paper {
    width: 360px;
  } */
  .popupBg {
    width: 825px;
    height: 500px;
  }
  .popupBg.delete-popup  {
    width: 825px;
    height: unset;
  }
  .hero_main {
    /* padding-top: 150px !important; */
  }
  .popupContain img {
    transform: scale(0.8);
  }
  .illu {
    width: 323px;
  }
  .yolohimg {
    /* top: -130px; */
  }

  .offer_card {
    padding: 25px !important;
  }
  .offer_card svg {
    left: 10px !important;
  }
  .join_section h1 {
    margin-bottom: 50px !important;
  }
  .popupContain {
    gap: 15px;
    padding-top: 50px;
  }
  /* .formContainMain {
    padding: 25px !important;
  } */
  .h-100 {
    height: 100%;
  }
  /* .overflow {
    overflow-y: auto;
    height: 100%;
  } */
  .overflow::-webkit-scrollbar {
    display: none;
  }
  .sideBarTopMenu span,
  .sideBarBottomMenu li {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 1799.98px) {
  .chat-header .userImg {
    height: 35px;
    width: 35px;
    font-size: 18px;
  }
  .chat-icon-info h4,
  .quotes_slider_header {
    font-size: 18px;
  }
  .chat-header {
    /* padding: 9px 40px; */
  }
  .my-chat-title {
    font-size: 18px;
    padding: 18px 0px 18px 10px;
  }
  .quotes_slider_header {
    height: 58px;
  }
  .quote-tabs .MuiTab-root {
    font-size: 16px;
    padding: 8px 16px;
  }
  .chat-agent-name p,
  .chat-agent-name h3,
  .chat-type input,
  .receiver-message {
    font-size: 16px;
  }
  .no-quotes-found-chat {
    font-size: 14px;
  }
  .my-chat-content {
    padding: 16px 20px;
  }
  .chat-agent-name {
    gap: 5px;
  }
  .chat-type {
    /* padding: 10px 20px !important; */
  }
  .chat-type input {
    /* padding: 10px 15px; */
  }
  .chat-type input::placeholder {
    font-size: 16px;
  }
  .imgArea img {
    width: 18px;
    height: 18px;
  }
  .send-btn {
    height: 35px;
    width: 35px;
  }
  .send-btn img {
    height: 16px;
    width: 16px;
  }
  .chat-body-inner {
    height: calc(100vh - 230px);
  }
  .my-chat-list {
    padding-top: 15px;
  }
  .agent_accordion .MuiAccordionSummary-root {
    padding: 15px !important;
  }
  .previewSteps {
    padding: 11px;
  }
  .preview-main-header.active-step::before {
    /* bottom: -9px;
    height: 2px; */
  }
  .my-chats-section {
    padding: 0 16px;
  }
  .quote_slider_inner p,
  .quote-card-prise,
  .chat-quotes-button {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .receiver p {
    font-size: 16px;
  }
  .preview-count {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
  .lead-Steps {
    padding: 10px;
    gap: 10px;
  }
  /* .active-step::before {
    height: 4px;
    bottom: -10px;
  } */
  .preview-main-header {
    gap: 10px;
  }
  .preview-main-header h1 {
    font-size: 15px;
  }
  .preview-main-header svg {
    height: 31px;
    width: 32px;
  }
}
@media only screen and (max-width: 1499px) {
  .main_modal {
    max-width: 450px !important;
  }

  .main_modal {
    transform: scale(0.9) translate(-55%, -55%);
  }
  .model-contain-main-responsive-upload {
    max-height: 80vh !important;
  }
  .model-contain-main-responsive-upload {
    padding: 25px !important;
  }
  .model-contain-main-responsive button {
    height: 45px !important;
    border: none !important;
    font-size: 18px !important;
  }
  .existingInsuranceLoginBtn {
    width: 300px;
  }
  .dividerMainPopup {
    margin: 25px 0px;
  }
  .dividerMainPopup div {
    font-size: 16px;
  }
  .p-40 {
    padding: 25px;
}
.FilesDragAndDrop__area{
  padding: 0px 15px !important;
}

.FilesDragAndDrop__area svg {
  height: 20px;
  width: 20px;
}
.FilesDragAndDrop__area {
  padding: 0px 10px !important;
  min-height: 60px !important;
}
.modal_logo {
  width: 133px;
  height: 25px;
  margin: 0 auto;
}
.modal_bg{
  border-radius: 10px;
}
.noflex .MuiGrid-spacing-xs-6 {
  display: block !important;
}
.noflex .MuiGrid-spacing-xs-6 .MuiGrid-grid-sm-6 {
  width: 100% !important;
  max-width: 100% !important;
}
}
@media only screen and (max-width: 1299px) {
  .insurance-field-error {
    top: 46px !important;
  }
  .contentTitleBox h3 {
    font-size: 16px !important;
  }
  .chat-header-right button {
    font-size: 16px !important;
    padding: 4px 10px !important;
  }
  .saveAsDraftBtn {
    width: 151px;
    height: 39px;
  }
  .nextbtnresponsive {
    height: 39px !important;
  }
  .quote-list-model .MuiDrawer-paper {
    width: 340px;
  }
  .partner_box {
    /* justify-content: center !important; */
  }
  .thirdHeader .notificationalert,
  .profile-title,
  .notification_contain h3,
  .notification_contain p,
  .notification_right {
    font-size: 16px;
  }
  .thirdHeader .notificationalert {
    padding: 11px;
    font-size: 15px !important;
  }
  .handwidth {
    width: 30%;
  }
  /* .container_width {
    padding: 0px 100px;
    max-width: 1024px;
  }
  .yolohimg {
    width: calc(100% - 200px);
  } */
  .illu {
    width: 323px;
  }
  .p-40 {
    padding: 25px;
  }
  .preview-main-header.active-step::before {
    bottom: -15px;
  }
  .mainSideBar,
  .image_col_bg {
    padding: 25px;
  }
  .sideBarUser .profileUserName {
    font-size: 16px;
  }
  .sideBarUser .sideBarUserIcon {
    height: 50px;
    width: 50px;
  }
  .chat-type {
    height: 75px !important;
  }
  .submitQuotesMain {
    padding: 25px !important;
  }
  /* .sideBarTopMenu,
  .sideBarBottomMenu {
    margin-top: 18px;
  padding-top: 18px;
  } */
  .customer-information-out-line > div {
    padding: 24px !important;
  }
  .inner-form-heading,
  .insurance-form-label,
  .insurance-label {
    font-size: 14px;
  }

  .insurance-text,
  .paddingforce input,.paddingforce div.MuiOutlinedInput-input {
    padding: 11.5px 10px !important;
    font-size: 14px !important;
  }
  .address_autocomplete .MuiAutocomplete-inputRoot  {
    padding: 0 !important;
  }
  .gap-row-30 {
    row-gap: 15px;
  }
  .chat-header-right button {
    font-size: 16px !important;
    padding: 4px 10px !important;
  }
  .preview-right-btn {
    padding: 12px 0;
  }
  .notFoundContain img {
    width: 73%;
    margin: 20px auto;
  }
 
  .modal_button {
    padding: 10px;
    width: 100%;
  }
  
 
 
  .modal_devider {
    margin: 16px auto 16px auto;
  }

  .titleWidth .image_col_bg_step_text {
    font-size: 16px;
    max-width: 180px !important;
  }
  .main-form-field label.MuiInputLabel-shrink{
    transform: translate(14px, -9px) scale(0.75)!important;
  }
  .main-form-field label + div{
    padding:  0 !important;
  }
.main-form-field label{
  transform: translate(11px, 11px) scale(1) !important;
  font-size: 14px !important;
}
  .res-modal-title {
    font-size: 28px;
  }
  .quotes_slider_header {
    padding: 0;
    font-size: 16px;
  }
  .my-chat-title,
  .vaultPinTitle,
  .chat-avatar,
  .chat-icon-info h4,
  .quotes_slider_header,
  .option_card_header p {
    font-size: 16px;
  }
 
  .chat-avatar {
    height: 25px;
    width: 25px;
  }
  .hero_text {
    padding-bottom: 125px !important;
  }
  
  .preview-main-header h1 {
    font-size: 14px;
  }

  .chat-agent-name p,
  .chat-agent-name h3,
  .chat-type input,
  .receiver-message,
  .receiver p {
    font-size: 14px;
  }
  .receiver-message {
    padding: 15px 25px;
  }
  .quote-card-is-accepted {
    font-size: 14px;
    padding: 5px 10px;
  }
  .css-o4b71y-MuiAccordionSummary-content {
    margin: 8px 0 !important;
  }
  .chat-header .userImg {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
 

  .vaultPinDescription {
    font-size: 14px;
  }
  .pin-section {
    padding: 30px 0px 30px 0px;
  }
  .vaultPinContain {
    margin-top: 25px;
    padding: 25px 0;
  }
  .forgetVaultPin {
    font-size: 14px;
    margin-top: 20px;
  }
  .view_button,
  .policy-tabs {
    font-size: 16px !important;
  }
  .step_img {
    height: 275px;
  }
  .chat-header-right button,
  .breadcrumb-item {
    font-size: 14px !important;
  }
  .insurance-subheading {
    padding-bottom: 20px;
  }
  .secondUserIcon {
    height: 35px;
    width: 35px;
    font-size: 16px;
  }
  .secondHeaderItem svg {
    font-size: 24px;
  }
  .secondHeaderItem {
    gap: 15px;
  }
  .option_card_header input {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
  .overview_title {
    font-size: 22px;
  }
  .MuiBadge-badge {
    font-size: 10px !important;
  }
  .insurance-details {
    margin-bottom: 20px;
    padding: 15px;
  }
  .verify-model-header p {
    font-size: 18px;
  }
  .insurance-title,
  .insurance-title-text,
  .preview-sub-header p {
    font-size: 16px;
    line-height: 20px;
  }
  .affiliationDetailHeading,.stateAndProductHeading {
    font-size: 20px;
  }
  .h-unset + .MuiInputBase-root .MuiOutlinedInput-input {
    height: 20px !important;
  }
 
  .model-contain .css-1h77wgb {
    display: block !important;
  }
  .css-1h77wgb .MuiGrid-grid-sm-6 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .preview-main-header {
    padding: 8px;
    gap: 10px;
  }
  .preview-sub-header {
    padding: 20px 0px 19px 75px;
    gap: 10px;
  }
  .insurance-subheading {
    font-size: 18px;
  }
  .insurance-label {
    font-size: 14px !important;
  }
  .quote-name,
  .quote_slider_inner p {
    font-size: 16px !important;
  }

  .quote_slider_inner p,
  .quote-card-prise,
  .chat-quotes-button {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .bindableTermsAndCondition label {
    font-size: 14px;
  }
  .quote_slider_contain {
    margin-bottom: 15px;
  }
  .MuiCollapse-wrapper .gap-20 {
    gap: 10px;
  }

  .overview_insurance_card {
    padding: 15px;
  }
  .quote_btn {
    height: 48px !important;
    font-size: 16px !important;
  }
  .card-side-bar{
    margin-top: -74px;
  }
  .py-30 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .info-icon img {
    height: 13px;
  }
  .px-30 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .bindableTermsAndCondition {
    font-size: 12px !important;
  }
  .previewSteps {
    padding: 11px;
  }
  .preview_edit_btn {
    padding: 7px 28px !important;
  }
  .modal_devider p {
    margin: 10px 0;
  }
  .dashboradTableMain{padding: 30px;}
  .viewPolicyDocs{
    font-size: 16px;
    height: 46px;
  }
}
@media only screen and (max-width: 1200px) {
  /* .chat-agent-name h3 {
    display: none !important;
  } */
  /* .active-chat-dot{
    display: none !important;
  } */
  /* .my-chat-content{
    gap: 0px;
    justify-content: center;
  } */
  /* .customer-information-input {
    width: 47%;
  } */

  .teams-contain {
    flex-direction: column;
    align-items: end;
    gap: 20px;
  }

  /* .container_width {
    max-width: 1024px;
    padding: 0px 50px;
  }

  .agent_container_width {
    max-width: 1150px;
    padding: 0px 50px;
  } */

  .QuoteListToggal {
    display: block !important;
  }

  .pr-75,
  .pl-75 {
    padding: 0 !important;
  }

  .thirdHeader {
    gap: 30px;
  }

  .card-side-bar {
    /* display: none; */
  }

  .agent-chat .chat-board {
    width: 100%;
  }
  .textcentermd {
    text-align: center;
  }
  .widthauto {
    width: fit-content;
  }
  .justifymd {
    align-items: center !important;
  }
  .hero_image_main {
    width: 60%;
  }
}
@media only screen and (max-width: 1199px) {
  .insurance-label {
    /* max-width: 40%;
    min-width: 40%; */
  }

  .insurance-info-main {
    /* justify-content: space-between; */
    width: -webkit-fill-available;
    width: -moz-available;
  }
}
/* @media only screen and (max-width: 1199px) {
  .insurance-label {
    max-width: 250px;
    min-width: 250px;
  }
} */

/* @media only screen and (max-width: 1080px) {
  .insurance-text,
  .insurance-text span {
    max-width: 190px;
  }
} */
@media only screen and (max-width: 1055px) {
  .side-bar {
    display: none;
  }
  .contact_section_main::before {
    width: 100% !important;
  }
  .activity-dashboard {
    margin-left: 0;
  }

  .grid-box {
    display: block;
  }

  .profile-input-section {
    padding: 30px;
  }

  .profile-input-main {
    justify-content: space-between;
  }

  .thirdHeader {
    gap: 20px;
  }

  .headerLink {
    margin-left: 0px;
    gap: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .loginBtn.loginBtnHeader.responsiveBtnHeader {
    display: none !important;
  }
  .yolohimg {
    position: absolute;
    /* width: calc(100% - 60px); */
    /* top: -125px; */
  }
  /* .container_width {
    max-width: 768px;
    padding: 0px 30px;
  } */

  .agent_container_width {
    /* max-width: 864px; */
    padding: 0px 30px;
  }

  /* .chat-body-inner-content {
    max-width: 804px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  } */

  .thirdHeader {
    gap: 7px;
  }

  .leander_user_model {
    width: 85% !important;
  }

  .main_modal {
    width: auto;
  }

  .model-contain-main-upload {
    width: 90% !important;
  }

  .sidebarToggal {
    display: flex !important;
    align-items: center;
    padding: 0;
    margin: 0;
    min-width: unset !important;
  }

  .headerLink {
    display: none;
  }

  .responsive-header {
    display: block;
  }
  .image_col_bg {
    padding: 30px;
  }
}

@media only screen and (max-width: 992px) {
  /* .layout {
    margin-top: 60px;
  } */
  .quote-list-model .MuiDrawer-paper {
    height: calc(100vh - 185px);
  }

  .chat-body-inner {
    height: calc(100vh - 389px);
  }

  /* .my-chat-list {
    height: calc(100vh - 292px);
  } */

  .help-chat-body-inner {
    height: calc(100vh - 254px);
  }

  .heroSection .slick-dots {
    margin: auto !important;
    text-align: center !important;
    width: 100% !important;
    bottom: 10px !important;
  }

  .document-footer {
    flex-direction: column;
    gap: 10px;
  }

  .heroImage img {
    width: 300px;
    margin: auto;
  }

  .insurance-header {
    top: 63px;
  }

  .setting-img {
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .setting-text {
    font-size: 22px;
  }

  .insuranceInfo {
    padding-left: 0px;
  }

  .layout {
    min-height: calc(100vh - 200px);
  }

  .about_card_main {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@media only screen and (max-width: 990px) {
  /* .insurance-text,
  .insurance-text span {
    max-width: 150px;
    min-width: 150px;
  } */
  /* .insurance-label {
    max-width: 150px;
    min-width: 150px;
  } */
}

/* @media only screen and (max-width: 899px) {
  .insurance-text,
  .insurance-text span {
    max-width: unset;
    min-width: unset;
  }
} */
@media only screen and (max-width: 899.98px) {
  .hero_main {
    height: 100% !important;
  }
  .hero_text {
    padding-bottom: 0 !important;
    padding-top: 200px !important;
  }
  .footer_details {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .footer_details div,
  .footer_details > p {
    width: 100%;
    justify-content: center;
  }
  .team_sub_desc .p_tag {
    max-width: unset;
  }

  .yolohimg {
    width: 95%;
  }
}

@media only screen and (max-width: 870px) {
  .form-radio-savan {
    width: 100% !important;
  }
}

@media only screen and (max-height: 600px) {
  .create-acc-res {
    height: 295px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  /* .chat_btn {
    display: none !important;
  } */
  .coverages_item p {
    padding: 0px 15px;
  }

  .chat_btn_icon {
    display: block;
  }

  .edit-button {
    display: none;
  }

  .edit-button-icon {
    display: flex;
  }

  .dashboard-card {
    width: 100%;
  }

  .add-res {
    flex-direction: column;
    gap: 0px;
  }

  .delete-popup {
    width: unset;
  }

  .notification_left {
    flex-direction: column;
    align-items: baseline;
  }

  .notification_card {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .survey-type-img {
    width: 110px;
  }

  /* .container_width {
    max-width: 450px;
    padding: 0px 30px;
  } */

  .agent_container_width {
    /* max-width: 680px; */
    padding: 0px 20px;
  }

  .customer-information-input {
    width: 100%;
  }

  .step_line {
    width: 847px;
    top: 54%;
  }

  .applicant-stap {
    background-color: var(--header-bg);
  }

  .applicant-stap {
    flex-direction: row;
    width: unset;
  }

  .main-step {
    justify-content: unset;
    gap: 40px;
  }

  .quotes-tab,
  .preview-tab {
    width: 150px;
  }

  .common-shadow {
    padding: 20px 20px;
  }

  .insurance-heading {
    margin-bottom: 14px;
  }

  .px-40 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .py-40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .insurance-info-main {
    justify-content: space-between;
  }

  .heroInfo {
    height: unset;
    justify-content: center;
  }

  .aboutSectionInner {
    justify-content: center;
  }

  .step_img {
    display: none;
  }

  /* .preview-main-header-section-inner {
    flex-direction: column;
    row-gap: 20px;
  } */

  .chat-body-inner {
    height: calc(100vh - 440px);
  }
}

@media only screen and (max-width: 600px) {

  .agent-details {
    flex-direction: column;
    row-gap: 20px;
  }

  .heroImage {
    margin: auto;
  }

  .form-section {
    padding: 20px;
  }

  .vaultPinBtn {
    width: auto;
  }

  .insurance-header {
    height: auto;
    padding: 10px 0;
  }

  .heroSection .slick-dots {
    bottom: 100px !important;
  }

  .productMain {
    justify-content: center;
  }

  .productImg {
    width: 200px !important;
    height: auto;
  }

  .quotes-tab,
  .preview-tab {
    width: 100px;
  }

  .edit-button {
    width: 100px;
  }

  .heroText h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .setting-text {
    font-size: 20px;
  }
  .hero_text {
    padding-top: 125px !important;
  }
  .customer-information-out-line {
    padding: 0px;
  }

  .insurance {
    padding: 20px 0;
  }

  .receiver-message {
    padding: 10px 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
  }

  .sender-message {
    padding: 10px 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
  }

  .insurance-text,
  .insurance-text span,
  .insurance-label {
    max-width: unset;
    min-width: unset;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .insurance-ul-list {
    max-width: unset;
    min-width: unset;
  }

  .insurance-info-main {
    flex-direction: column;
    gap: 0;
  }

  .profile-input-section {
    padding: 20px;
  }
}

.moreIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
  display: none !important;
}

.responsive-my-chats-section .my-chats-section {
  display: block !important;
}

@media only screen and (max-width: 1535px) {
  .chat-agent-name p {
    display: none !important;
  }

  .my-chat-content {
    padding: 12px 15px !important;
  }

  /* .my-chats-section {
    display: none !important;
  }
  .moreIcon {

    display: block !important;
  }
  .quotes-list-close{
    display: block !important;
  }
  
  .my-chat-list {
    max-height: calc(100vh - 110px) !important;
  } */
}

@media only screen and (max-width: 900px) {
  .my-chats-section {
    display: none !important;
    width: 370px !important;
  }

  .quote-list-model .MuiDrawer-paper {
    height: 100%;
    bottom: unset;
    right: 0;
    border: unset;
    top: 0;
  }

  /* .my-chats-section{

  } */
  .my-chat-content {
    gap: 15px;
    justify-content: unset;
  }

  .chat-agent-name p,
  .chat-agent-name h3 {
    display: block !important;
  }

  .active-chat-dot {
    display: block !important;
  }

  .quotes-list-close {
    display: block !important;
  }

  .moreIcon {
    display: block !important;
  }

  .my-chat-list {
    max-height: calc(100vh - 110px) !important;
  }
}

@media only screen and (max-width: 484px) {
  .survey-type-img {
    width: 90px;
    height: 80px;
  }

  .moreIcon {
    right: 75px;
  }
}

@media only screen and (max-height: 425px) {
  .main_modal_add_id {
    width: max-content;
  }
  .chat-header-right button {
    padding: 6px 10px !important;
  }
}

@media only screen and (max-width: 550px) {
  .insurance-details {
    flex-direction: column;
  }

  .insurance-type,
  .insurance-status {
    text-align: center;
  }

  .separate-line {
    width: -webkit-fill-available !important;
    height: 1px;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 425px) {
  /* .container_width {
    max-width: 375px;
    padding: 0px 30px;
  } */

  .agent_container_width {
    /* max-width: 385px; */
    padding: 0px 20px;
  }

  .chat-header-right button {
    padding: 6px 10px !important;
  }

  .agent-connect-btn {
    flex-direction: column;
  }

  .otpInput {
    width: 50px;
  }

  .survey-type-img {
    width: 50px;
    height: 50px;
  }
}
